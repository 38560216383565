import {Box, Flex, FormControl, FormLabel, Input, Stack} from "@chakra-ui/react";
import React from "react";

export default function NowTimestampMappingView() {
    return <FormControl isDisabled>
        <Stack spacing={"1em"}>
            <Flex>
                <Box w={"50%"}>
                    <FormLabel>Value</FormLabel>
                    <Input size={"sm"}
                           value={"NOW()"}/>
                </Box>
            </Flex>
        </Stack>
    </FormControl>
}
