import {Box} from "@chakra-ui/react";
import {
    Column,
    HeaderCell,
    NumberCell,
    ReactGrid,
    Row,
    TextCell,
    CellChange,
    DefaultCellTypes, DropdownCell, OptionType
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import {useEffect, useState} from "react";
import {MappingPairsInput} from "../../gql/graphql";



interface ExcelGridTypedProps{
    columns: string[]
    mappingPairs: MappingPairsInput[]
    rowCount: number
    values: string[]
    updatePairs: (pairs: MappingPairsInput[]) => void
}

export default function ExcelGridTyped(props: ExcelGridTypedProps) {
    const options = props.values.map((v):OptionType => {return {label:v, value:v}})
    const dataFromPairs = getInitialRows(props.mappingPairs, props.rowCount, options)

    const [data, setData] = useState<Row[]>(dataFromPairs)
    const [pairs, setPairs] = useState<MappingPairsInput[]>(props.mappingPairs)

    const columns = getExcelColumns(props.columns);

    const handleChanges = (changes: CellChange<DefaultCellTypes>[]) => {
        setData((prev) => applyChangesToData(changes, prev))
        setPairs(getPairsFromRows(data, props.updatePairs))
    };

    return (
        <Box borderWidth='1px' borderRadius='lg' p="5" resize='both' height='450px' overflow='auto' bg='purple.50'>
            <ReactGrid
                enableRangeSelection
                // enableColumnSelection
                // enableRowSelection
                rows={data}
                columns={columns}
                onCellsChanged={handleChanges}

            />
        </Box>
    );
}

function getPairsFromRows(rows: Row[], updatePairs: (pairs: MappingPairsInput[]) => void):MappingPairsInput[] {
    const result = rows.reduce<MappingPairsInput[]>(function(result, row) {
        const key = (row.cells[0] as TextCell).text
        const val = (row.cells[1] as DropdownCell).selectedValue?? ""
        if (key !== "" || val !== "") {
            result.push({key: key, value: val});
        }
        return result;
    }, [])
    updatePairs(result)
    return result
}

function getInitialRows(pairs: MappingPairsInput[], rowCount: number, options: OptionType[]):Row[] {
    const dataFromPairs = pairs.map((p, idx): Row => {
        const key: TextCell = { type: "text", text: p.key }
        const val: DropdownCell = { type: "dropdown", selectedValue: p.value, values: options}
        return {
            rowId: idx,
            cells: [key, val]
    }})
    if (pairs.length < rowCount) {
        dataFromPairs.push(...[...Array(rowCount - pairs.length)]
            .map((v, idx) => {
                const key: TextCell = {type: "text", text: ""}
                const val: DropdownCell = {type: "dropdown", selectedValue: "", values: options}
                return {
                    rowId: idx + pairs.length,
                    cells: [key, val]
                }
            }))
    }
    return dataFromPairs
}
const applyChangesToData = (
    changes: CellChange<DefaultCellTypes>[],
    prev: Row[],
): Row[] => {
    changes.forEach((change) => {
        const rowIdx = change.rowId as number;
        const colIdx = change.columnId as number;
        switch (colIdx) {
            case 0:
                const newCell1 = prev[rowIdx].cells[0] as TextCell
                newCell1.text = (change.newCell as TextCell).text
                prev[rowIdx].cells[0] = newCell1
                break
            case 1:
                const newCell2 = prev[rowIdx].cells[1] as DropdownCell
                newCell2.selectedValue = (change.newCell as DropdownCell).selectedValue
                newCell2.isOpen = (change.newCell as DropdownCell).isOpen
                prev[rowIdx].cells[1] = newCell2
                break
        }
    })
    return [...prev]
};
const getExcelColumns = function (cols: string[]): Column[] {
    return [
        ...cols.map((col,idx) => {
            return {columnId: idx, width: 250}
        })
    ]
}

const getExcelRows = function (rows: string[][], cols: string[], options: OptionType[]): Row[] {
    const headerRow: Row = {
        rowId: "header",
        cells: [
            ...cols.map((col): HeaderCell => {
                return {type: "header", text: col, nonEditable:false}
            })
        ]
    }
    return [
        headerRow,
        ...rows.map((pair, idx): Row => {
            const key: TextCell = {type: "text", text: pair[0]}
            const type: DropdownCell = {
                type: "dropdown",
                selectedValue: pair[1],
                values:options, isDisabled:false, nonEditable:false}
                return {
                    rowId: idx,
                    cells: [key, type]
                }
            }
        )
    ]
}
