import {As, Button, ButtonProps, HStack, Icon, Text} from '@chakra-ui/react'
import {NavLink, NavLinkProps} from "react-router-dom";

type NavButtonProps = Omit<ButtonProps, "children"> & NavLinkProps &{
    icon: As
    label: string
}

export default function NavButton(props: NavButtonProps) {
    const {icon, label, ...buttonProps} = props
    return (
        <Button as={NavLink} size='sm' variant="ghost-on-accent" justifyContent="start"  {...buttonProps}>
            <HStack spacing="3">
                <Icon as={icon} boxSize="5" color="on-accent-subtle"/>
                <Text> {label} </Text>
            </HStack>
        </Button>)
}
