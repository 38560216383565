import {gql, useQuery} from "@apollo/client";
import {Button, Container, Heading, Link, Stack, Text} from "@chakra-ui/react";
import React, {ReactNode, useState} from "react";
import {
    GetOccupancyQuery,
    GetOccupancyQueryVariables,
    PortfolioData,
    PortfolioRows,
    UnitModel
} from "../../../gql/graphql";
import GenericTable from "../../tables/GenericTable";
import {createColumnHelper} from "@tanstack/react-table";
import UnitListTable from "../../tables/UnitModelTable";
import {SingleDatepicker} from "chakra-dayzed-datepicker";
import {DateToHyphenYYYYMMDD} from "../../../utils/strings";

const OCCUPANCY_QUERY = gql`
    query getOccupancy($date: String!) {
        getOccupancy(date: $date) {
            columns
            dataRows {
                subRows {
                    name
                    data
                    units {
                        address
                        external_ref
                        id
                        state
                        city
                        year_built
                        bed
                        bath
                    }
                    subRows {
                        name
                        data
                        units {
                            address
                            external_ref
                            id
                            state
                            city
                            year_built
                            bed
                            bath
                        }
                    }
                }
                name
                data
            }
        }
    }
`


export default function Occupancy() {
    const [date, setDate] = useState(new Date());
    const {data, loading, error} = useQuery<GetOccupancyQuery, GetOccupancyQueryVariables>(OCCUPANCY_QUERY, {
       variables: {
           date: DateToHyphenYYYYMMDD(date)
       }
    });
    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>
    console.log(data)
    let t = data.getOccupancy
    return (<Container>
        <Stack direction={"row"} spacing="1">
            <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                Occupancy View
            </Heading>
            <SingleDatepicker
                name="date-input"
                date={date}
                onDateChange={setDate}
                propsConfigs={{
                    inputProps: {
                        size: "sm",
                        width: "120px"
                    }
                }}
            />

        </Stack>
        <PortfolioTable data={data.getOccupancy as PortfolioData}/>

        <Stack>
        </Stack>
    </Container>)
}


interface PortfolioTableProps {
    data: PortfolioData
}

function PortfolioTable(props: PortfolioTableProps) {
    const [units, setUnits] = React.useState<UnitModel[]>([])

    const noiColumnHelper = createColumnHelper<PortfolioRows>()

    let noiRows = [
        noiColumnHelper.accessor(row => row.name, {
            id: 'Title',
            cell: ({row, getValue}) => (
                <Text
                    pl={
                        // Since rows are flattened by default,
                        // we can use the row.depth property
                        // and paddingLeft to visually indicate the depth
                        // of the row
                        `${row.depth * 2}rem`
                    }
                    // href={`/list-view/${row.getValue("value1")}`}
                >
                    <>
                        {' '}
                        {row.getCanExpand() ? (
                            <button
                                {...{
                                    onClick: row.getToggleExpandedHandler(),
                                    style: {cursor: 'pointer'},
                                }}
                            >
                                {row.getIsExpanded() ? '➖' : '➕'}
                            </button>
                        ) : (
                            ' '
                        )}{' '}
                        {
                            <Link onClick={() => {
                                setUnits(row.original.units)
                                console.log(units.length)
                            }}>{getValue()}</Link>
                        }
                    </>
                </Text>),
            header: '',
        }),
    ]

    noiRows = noiRows.concat(props.data.columns.map(
        (r, idx) => {
            return noiColumnHelper.accessor(row => row.data[idx], {
                id: `Data-${idx}`,
                cell: info => info.getValue(),
                meta: {isNumeric: true},
                header: r,
            })
        }
    ))
    return (<Stack spacing={"1em"}>
        <GenericTable<PortfolioRows>
            data={props.data.dataRows as PortfolioRows[]}
            columns={noiRows}
            subrows={row => row.subRows}
            expandedState={true}
        />
        <UnitListTable
            unitData={units}
            filter={true}
        />
    </Stack>)
}

interface TextByDepthProps{
    depth?: number
    children: ReactNode

}
function TextByDepth(props: TextByDepthProps) {
    let size = 1
    if (props.depth != null) {
        size = 1 - (props.depth * .2)
        console.log(size)
    }
    return <Text size={`${size}rem`}>{props.children}</Text>
}
