import {AppState, Auth0Provider} from "@auth0/auth0-react";
import React from "react";
import {useNavigate} from "react-router-dom";

interface Auth0ProviderWithNavigateProps {
    children?: React.ReactNode;
}
export default function Auth0ProviderWithNavigate({children}: Auth0ProviderWithNavigateProps) {
    const navigate = useNavigate();
    const domain = process.env.REACT_APP_AUTH0_DOMAIN
    if (!domain) {
        throw new Error("process.env.REACT_APP_AUTH0_DOMAIN not defined")
    }
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
    if (!clientId) {
        throw new Error("process.env.REACT_APP_AUTH0_CLIENT_ID not defined")
    }
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL
    if (!redirectUri) {
        throw new Error("process.env.REACT_APP_AUTH0_CALLBACK_URL not defined")
    }
    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };
    if (!(domain && clientId && redirectUri)) {
        return null;
    }
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}
