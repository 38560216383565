import {Box, Heading} from "@chakra-ui/react";
import {gql, useLazyQuery} from "@apollo/client";
import {SupersetGuestTokenQuery} from "../../gql/graphql";
import {useEffect, useState} from "react";
import {embedDashboard} from "@superset-ui/embedded-sdk";

const SUPERSET_GUEST_TOKEN = gql`
    query supersetGuestToken {
        supersetGuestToken
    }
`;

interface EmbeddedReportProps {
    supersetDomain: string
}
export default function EmbeddedReport(props: EmbeddedReportProps) {
    const [token, setToken] = useState("");
    const [loadGreeting, {called, loading, data}] = useLazyQuery<SupersetGuestTokenQuery>(SUPERSET_GUEST_TOKEN, {
        onCompleted: (data) => {
            setToken(data.supersetGuestToken)
        }
    });
    useEffect(() => {
        loadGreeting()
        const mount = document.getElementById("my-superset-container");
        if (mount && data) {
            embedDashboard({
                id: "bc199af6-c836-4b79-8269-41e9ea1a59b3", // given by the Superset embedding UI
                supersetDomain: props.supersetDomain,
                fetchGuestToken: () => new Promise<string>((resolve) => {
                    resolve(token)
                }),
                mountPoint: mount, // any html element that can contain an iframe
                dashboardUiConfig: {hideTitle: true} // dashboard UI config: hideTitle, hideTab, hideChartControls (optional)
            })
        }
    }, [token]);

    return (<><Box w={'100%'} h={'80vh'} sx={{'iframe': {width: '100%', height: '100%'}}}><Heading>Hello Embedded
        Reports</Heading>
        <div style={{height: '100%', width: '100%'}} id="my-superset-container" className="IFrameLoader"></div>
    </Box></>)
}
