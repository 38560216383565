import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react'
import { Logo } from '../assets/Logo'
import Sidebar from './Sidebar'
import { ToggleButton } from './ToggleButton'
import React from "react";

export default function Navbar() {
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <Box width="full" py="4" px={{ base: '4', md: '8' }} bg="bg-accent">
      <Flex justify="space-between">
        <Logo color="on-accent"/>
        <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          isFullHeight
          preserveScrollBarGap
          // Only disabled for showcase
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Sidebar />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  )
}
