import {Box, Flex, FormControl, FormLabel, Input, Select, Spacer, Stack} from "@chakra-ui/react";
import {FastField} from "formik";
import React from "react";
import {FieldMappingViewProps} from "../FieldMappingView";
import ColumnSelect from "./ColumnSelect";

interface RegexMappingViewProps extends FieldMappingViewProps {
    inputNamePrefix: (fieldIdx: number) => string
    inputValue: string
}

export default function RegexMappingView(props: RegexMappingViewProps) {
    return <FormControl>
        <Stack spacing={"1em"}>
            <Flex>
                <Box w={"50%"}>
                    <FormLabel>Column</FormLabel>
                    <ColumnSelect {...props}/>
                </Box>
                <Spacer w={"1em"}/>
                <Box w={"50%"}>
                    <FormLabel>Value</FormLabel>
                    <Input as={FastField}
                           size={"sm"}
                           id={props.inputNamePrefix(props.idx)}
                           name={props.inputNamePrefix(props.idx)}
                           value={props.inputValue}
                           onChange={props.formikOnChange}/>
                </Box>
            </Flex>
        </Stack>
    </FormControl>
}
