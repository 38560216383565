import {Box, Button, ButtonGroup, Container, Flex, HStack, IconButton, useBreakpointValue,Link} from '@chakra-ui/react'
import {FiMenu} from 'react-icons/fi'
import {Logo} from '../assets/Logo'
import {useAuth0} from "@auth0/auth0-react";
import LoginButton from "../authentication/LoginButton";
import React from "react";
import LogoutButton from "../authentication/LogoutButton";

export default function NavBar() {
    const isDesktop = useBreakpointValue({base: false, lg: true})
    const {isAuthenticated, isLoading} = useAuth0()
    return (
        <Box as="section">
            <Box as="nav" bg="bg-surface" boxShadow="sm">
                <Container py={{base: '4', lg: '5'}}>
                    <HStack spacing="10" justify="space-between">
                        <Link href="/"><Logo color="accent" /></Link>
                        {isDesktop ? (
                            <Flex justify="space-between" flex="1">
                                <ButtonGroup variant="link" spacing="8">
                                    <Button as={Link} key={"api-docs"} href="/api-docs">API Docs</Button>
                                </ButtonGroup>
                                <HStack spacing="3">
                                    {isAuthenticated ?
                                        <><Button as={Link} href="/app" variant="primary">Go to app</Button>
                                            <LogoutButton variant="ghost"/></> :
                                        <LoginButton variant="ghost"/>
                                    }
                                </HStack>
                            </Flex>
                        ) : (
                            <IconButton
                                variant="ghost"
                                icon={<FiMenu fontSize="1.25rem"/>}
                                aria-label="Open Menu"
                            />
                        )}
                    </HStack>
                </Container>
            </Box>
        </Box>
    )
}
