import {Box, Center, Container, Flex, Stack, Text, useBreakpointValue,} from '@chakra-ui/react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import {Outlet} from "react-router-dom";
import React from "react";
import {gql, useQuery} from "@apollo/client";
import {ImpersonationQuery} from "../../gql/graphql";

const IMPERSONATION = gql`
  query impersonation {
    currentImpersonation
  }
`;
export default function AppShell() {
  const isDesktop = useBreakpointValue({base: false, lg: true})
  const {data, loading, error} = useQuery<ImpersonationQuery>(IMPERSONATION);
  if (loading) return <Text>Loading...</Text>
  let color = "red.200"
  if (data) {
    switch (parseInt(data.currentImpersonation) % 5) {
      case 0:
        color = "pink.200"
        break
      case 1:
        color = "teal.200"
        break
      case 2:
        color = "green.200"
        break
      case 3:
        color = "yellow.200"
        break
      case 4:
        color = "orange.200"
        break
      default:
        break
    }
  }

  return (
        <>
          {data?.currentImpersonation ? <Center w={"100%"} h={"2em"} bgColor={color}>Currently impersonating company id: {data.currentImpersonation}</Center> : <></>}
            <Flex
                as="section"
                direction={{base: 'column', lg: 'row'}}
                height="100vh"
                bg="bg-canvas"
                overflowY="auto"
            >
                {isDesktop ? <Sidebar/> : <Navbar/>}
                <Container py="8" flex="1" maxW={"100%"} overflowY="auto">
                    <Stack spacing={{base: '8', lg: '6'}}>
                        <Outlet/>
                    </Stack>
                </Container>
            </Flex>
        </>
    )
}
