import * as React from 'react'

import {createColumnHelper} from '@tanstack/react-table'
import {Link, Text} from "@chakra-ui/react";
import {NumericFormat} from "react-number-format";
import GenericTable from "../tables/GenericTable";

export type SubrowData = {
    firstCol: string
    url:string
    value1: number
    perc1: number
    value2: number
    perc2: number
    value3: number
    perc3: number
    subrows: SubrowData[]
}

const columnHelper = createColumnHelper<SubrowData>()

const columns = [
    columnHelper.accessor('firstCol', {
        cell: ({row, getValue}) => (
            <Text
                pl={
                    // Since rows are flattened by default,
                    // we can use the row.depth property
                    // and paddingLeft to visually indicate the depth
                    // of the row
                    `${row.depth * 2}rem`
                }
                // href={`/list-view/${row.getValue("value1")}`}
            >
                <>
                    {' '}
                    {row.getCanExpand() ? (
                        <button
                            {...{
                                onClick: row.getToggleExpandedHandler(),
                                style: {cursor: 'pointer'},
                            }}
                        >
                            {row.getIsExpanded() ? '➖' : '➕'}
                        </button>
                    ) : (
                        ' '
                    )}{' '}
                    {
                        <Link href={row.original.url}>
                            {getValue()}
                        </Link>
                    }
                </>
            </Text>),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('value1', {
        cell: info => <NumericFormat displayType="text" value={info.renderValue()} prefix="$" thousandSeparator=","/>,
        header: () => "Value 1",
        footer: info => info.column.id,
        meta: {isNumeric: true},
    }),
    columnHelper.accessor('perc1', {
        header: () => 'Perc 1',
        cell: info => <NumericFormat displayType="text" value={info.renderValue()} suffix="%" thousandSeparator=","/>,
        footer: info => info.column.id,
        meta: {isNumeric: true},

    }),
    columnHelper.accessor('value2', {
        cell: info => <i>{info.renderValue()}</i>,
        header: () => "Value 2",
        footer: info => info.column.id,
    }),
    columnHelper.accessor('perc2', {
        header: () => 'Perc 2',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('value3', {
        cell: info => <i>{info.renderValue()}</i>,
        header: () => "Value 3",
        footer: info => info.column.id,
    }),
    columnHelper.accessor('perc3', {
        header: () => 'Perc 3',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
]

const data: SubrowData[] = [
    {
        firstCol: "Total",
        url: "/url1",
        value1: 900,
        perc1: 100,
        value2: 100,
        perc2: 1,
        value3: 100,
        perc3: 1,
        subrows: [
            {
                firstCol: "SubTotal1",
                url: "/url2",
                value1: 100,
                perc1: 1,
                value2: 100,
                perc2: 1,
                value3: 100,
                perc3: 1,
                subrows: [],
            },
            {
                firstCol: "SubTotal2",
                url: "/url3",
                value1: 100,
                perc1: 1,
                value2: 100,
                perc2: 1,
                value3: 100,
                perc3: 1,
                subrows: [],
            }
        ],
    },
    {
        firstCol: "Total2",
        url: "/url1",
        value1: 9000,
        perc1: 1000,
        value2: 1000,
        perc2: 10,
        value3: 1000,
        perc3: 10,
        subrows: [
            {
                firstCol: "SubTotal3",
                url: "/url2",
                value1: 100,
                perc1: 1,
                value2: 100,
                perc2: 1,
                value3: 100,
                perc3: 1,
                subrows: [],
            },
            {
                firstCol: "SubTotal4",
                url: "/url3",
                value1: 100,
                perc1: 1,
                value2: 100,
                perc2: 1,
                value3: 100,
                perc3: 1,
                subrows: [],
            }
        ],
    }
]
export default function SubRowTable() {
    return <GenericTable data={data} columns={columns} subrows={row => row.subrows}/>
}
