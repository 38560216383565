import {Container, Heading, Stack, Text} from "@chakra-ui/react";
import React from "react";
import {gql, useQuery} from "@apollo/client";
import ExcelGrid from "../../tables/ExcelGrid";
import {GetGridDataQuery, GetIDsQuery, TableType} from "../../../gql/graphql";

const GET_IDS = gql`
    query getIDs {
        getIDs {
            columns
            rows
        }
    }
`
export default function IDMappingView() {
    const {data, loading, error} = useQuery<GetIDsQuery>(GET_IDS);
    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>
    return <Container>
        <Stack spacing="1">
            <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                Mapping
            </Heading>
            <Text color="muted">This is the id mapping</Text>
            <ExcelGrid rows={data.getIDs.rows} columns={data.getIDs.columns}/>
        </Stack>
    </Container>
}
