import { ReactElement } from 'react';
import { Box, SimpleGrid, Text, Stack, Flex, VStack } from '@chakra-ui/react';

export interface FeatureProps {
    title: string;
    text: string;
    icon?: ReactElement;
    largeTitle?: boolean
}

const Feature = (props: FeatureProps) => {
    return (
        <Stack>
            {props.icon?
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'gray.100'}
                mb={1}>
                {props.icon}
            </Flex>
                : <></>}
            <Text fontSize={props.largeTitle ? 30 :20} fontWeight={600}>{props.title}</Text>
            <Text color={'gray.600'}>{props.text}</Text>
        </Stack>
    );
};

interface CTAThreeColumnsProps {
    feature1: FeatureProps
    feature2: FeatureProps
    feature3: FeatureProps
    feature4?: FeatureProps
    feature5?: FeatureProps
}
export default function CTATop({feature1, feature2, feature3, feature4, feature5}: CTAThreeColumnsProps) {
    return (
        <VStack p={4} spacing={"1em"}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                <Feature {...feature1} />
                <Feature {...feature2} />
                <Feature {...feature3} />
            </SimpleGrid>
            {feature4 && feature5 ?
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width={"68%"}>
                <Feature {...feature4} />
                <Feature {...feature5} />
            </SimpleGrid> : <></>
            }
        </VStack>
    );
}
