import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Accordion,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Select,
    Spacer,
    Stack,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import React from "react";
import {
    FieldMappingInput,
    GetMappingQuery,
    GetMappingQueryVariables,
    SyncSourceMutationVariables,
    TableMappingInput,
    TableType,
    UpdateMappingMutation,
    UpdateMappingMutationVariables
} from "../../../gql/graphql";
import FieldMappingView from "./FieldMappingView";
import {FastField, FieldArray, Formik} from "formik";
import AlertTop from "../../notification/AlertTop";
import {useParams} from "react-router-dom";

const GET_MAPPINGS = gql`
    query GetMapping($tableType: TableType!, $sourceID: Int) {
        getMappingInput(tableType: $tableType, sourceID: $sourceID, createDefaultIfNotExists: true) {
            sourceTableName
            sourceSchemaName
            type
            availableColumns
            filter
            idExternalRefType
            targetEditable
            fieldMappings {
                mappingType
                targetType
                targetColumn
                sourceColumn
                fixedMapping
                parseTimestampFormat
                regexFieldMapping
                selectSQLMapping
                exactTypedMapping {
                    key
                    value
                }
                startsWithTypedMapping {
                    key
                    value
                }
                regexTypedMapping {
                    key
                    value
                }
                optional
                availableValues
                availableMappingTypes
            }
        }
        getSources {
            id
            name
            tables {
                name
                columns
            }
        }
    }
`

const GET_SOURCES = gql`
    query getSource {
        getSources {
            name
            tables {
                name
                columns
            }

        }
    }
`

const MUTATE_MAPPING = gql`
    mutation UpdateMapping($input: TableMappingInput!) {
        updateMapping(input: $input)
    }
`

const MUTATE_SYNC = gql`
    mutation SyncSource($tableType: TableType!, $sourceID: Int!) {
        sync(tableType: $tableType, sourceID: $sourceID)
    }
`

interface MappingTableProps {
    type?: TableType
}

export default function MappingTableView(props: MappingTableProps) {
    let {data_type, source_id} = useParams();
    let queryTableType:TableType = props.type ??
        Object.values(TableType).find(val => val.toLowerCase() == data_type?.toLowerCase()) as TableType
    const skip = queryTableType == null || source_id == null
    const sourceIDInt = source_id ? parseFloat(source_id) : 0
    const {
        isOpen: isVisible,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: false })
    const inputVar: GetMappingQueryVariables = {
        tableType: queryTableType,
        sourceID: sourceIDInt,
    }
    const {data, loading, error, refetch} = useQuery<GetMappingQuery>(GET_MAPPINGS, {
        variables: inputVar,
        skip: skip
    });
    const [submit] = useMutation<UpdateMappingMutation>(MUTATE_MAPPING, {
        awaitRefetchQueries: true,
        onCompleted: (data)=> {onOpen()}
    });
    const [sync] = useMutation<SyncSourceMutationVariables>(MUTATE_SYNC, {
        awaitRefetchQueries: true
    });

    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>

    return <Container>
        <Stack spacing="1">
            {/*make generic for all pages*/}
            { isVisible && <AlertTop text={"Update success"} onClose={onClose}/>}
            <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                Mapping Table
            </Heading>
            <Flex>
                <Heading size={{base: 'xs', md: 'sm'}} color="muted">{queryTableType} Type</Heading>
                <Spacer/>
                <Button onClick={() => sync({
                    variables: {
                        tableType: queryTableType,
                        sourceID: sourceIDInt
                    }
                }).then(value => alert("Syncing done")).catch(value => alert(value))}>Sync</Button>
            </Flex>
            <Divider/>
            <Stack>
                <Formik
                    initialValues={{
                        input: {
                            fieldMappings: data.getMappingInput[0].fieldMappings ?
                                data.getMappingInput[0].fieldMappings.map((f):FieldMappingInput => {
                                    return {
                                        exactTypedMapping: f.exactTypedMapping,
                                        mappingType: f.mappingType,
                                        optional: f.optional,
                                        parseTimestampFormat: f.parseTimestampFormat,
                                        sourceColumn: f.sourceColumn,
                                        startsWithTypedMapping: f.startsWithTypedMapping,
                                        selectSQLMapping: f.selectSQLMapping,
                                        targetColumn: f.targetColumn,
                                        targetType: f.targetType,
                                        fixedMapping: f.fixedMapping,
                                        regexFieldMapping: f.regexFieldMapping
                                    }
                                }) : [],
                            filter: data.getMappingInput[0].filter,
                            sourceSchemaName: data.getMappingInput[0].sourceSchemaName ?? "",
                            sourceTableName: data.getMappingInput[0].sourceTableName ?? "",
                            type: data.getMappingInput[0].type ?? "",
                            idExternalRefType: data.getMappingInput[0].idExternalRefType ?? "",
                            sourceID: sourceIDInt.toString(),
                            targetEditable: data.getMappingInput[0].targetEditable,
                        },
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validateOnMount={false}
                    enableReinitialize={true}

                    onSubmit={(
                        values: UpdateMappingMutationVariables
                    ) => {
                        console.log(values)
                        if (data) {
                            // this should really be populated with the form input
                            let source = data.getSources.find(s => s.name == values.input.sourceSchemaName)
                            values.input.sourceID = source ? source.id : ""
                        }
                        submit({variables: values}).then(v => refetch())
                        // window.location.reload();
                    }}>
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Stack spacing={"1em"}>
                                <TableConfig
                                    formik={props.values.input}
                                    formikOnChange={props.handleChange}
                                    schemas={ data?.getSources?.map(s => s.name)??[]}
                                    schemaTables={data?.getSources?.reduce(function (map:Map<string, string[]>, obj) {
                                        map.set(obj.name , obj.tables?.map(t => t.name) ?? [])
                                        return map
                                    }, new Map<string, string[]>() )}
                                />

                                <Divider/>
                                <FormControl>
                                    <FormLabel>Field Mapping</FormLabel>
                                    <Flex py={"1em"}>
                                        <Text w='200px' fontWeight={"medium"}
                                              paddingLeft={"1em"}>Field</Text>
                                        <Text flex={4}
                                              fontWeight={"medium"}
                                              paddingLeft={"1em"}>Description</Text>
                                    </Flex>
                                    <Accordion allowMultiple>
                                        {data?.getMappingInput[0].fieldMappings?.map((f, idx) => {
                                            return <FieldMappingView
                                                key={f.targetColumn}
                                                targetColumn={f.targetColumn}
                                                targetType={f.targetType}
                                                sourceColumn={f.sourceColumn}
                                                mappingType={f.mappingType}
                                                fixedMapping={f.fixedMapping}
                                                regexFieldMapping={f.regexFieldMapping}
                                                exactMappingPairs={f.exactTypedMapping ?? []}
                                                selectSQLMapping={f.selectSQLMapping}
                                                toolTip={"Test \n Tooltip"}
                                                availableMapping={f.availableMappingTypes}
                                                availableValues={f.availableValues ?? []}
                                                availableColumns={data?.getMappingInput[0]?.availableColumns ?? []}
                                                formik={props.values.input.fieldMappings[idx]}
                                                formikOnChange={props.handleChange}
                                                idx={idx}
                                                targetEditable={data.getMappingInput[0].targetEditable}
                                            />
                                        })}
                                    </Accordion>
                                </FormControl>
                            </Stack>
                        </form>
                    )}
                </Formik>
            </Stack>
        </Stack>
    </Container>
}


interface TableConfigProps {
    formik: TableMappingInput
    formikOnChange: (e: React.ChangeEvent<any>) => void
    schemas:string[]
    schemaTables: Map<string, string[]>
}

function TableConfig(props: TableConfigProps) {
    return <>
        <FormControl>
            <Stack
                direction={{base: 'column', md: 'row'}}
                spacing={{base: '1.5', md: '3'}}
                justify="space-between"
            >
                <FormLabel variant="inline">Data object level config</FormLabel>
                <Stack w={{md: '3xl'}}>
                    <FormLabel maxW={{md: '3xl'}}>Source Schema</FormLabel>
                    <Select
                        size={"sm"}
                        name={"input.sourceSchemaName"}
                        onChange={props.formikOnChange}
                        value={props.formik.sourceSchemaName}
                        placeholder={"Pick a database schema"}>
                        {props.schemas.map(s => (<option>{s}</option>))}
                    </Select>
                    <FormLabel maxW={{md: '3xl'}}>Source Table</FormLabel>

                    <Select
                        size={"sm"}
                        name={"input.sourceTableName"}
                        onChange={props.formikOnChange}
                        value={props.formik.sourceTableName}
                        placeholder={"Pick a table"}>
                        {props.schemaTables.get(props.formik.sourceSchemaName)?.map(s => (<option>{s}</option>))}
                    </Select>
                    <FormLabel maxW={{md: '3xl'}}>ID Mapping Reference Name</FormLabel>
                    <Input as={FastField}
                           size={"sm"}
                           maxW={{md: '3xl'}}
                           id={"input.idExternalRefType"}
                           name={"input.idExternalRefType"}
                           value={props.formik.idExternalRefType ?? ""}
                           onChange={props.formikOnChange}/>
                </Stack>
            </Stack>
        </FormControl>
        <Divider/>
        <FormControl>
            <Stack
                direction={{base: 'column', md: 'row'}}
                spacing={{base: '1.5', md: '3'}}
                justify="space-between"
            >
                <FormLabel variant="inline">Table Filtering</FormLabel>
                <HStack w={{md: '3xl'}}>
                    <Box width={"100%"}>
                        <FormLabel maxW={{md: '3xl'}}>Filter (sql)</FormLabel>
                        <FieldArray name="input.filter">
                            {({insert, remove, push}) => (
                                <Stack spacing={"1em"}>
                                    {props.formik.filter && props.formik.filter.length > 0 &&
                                        props.formik.filter.map((fil, index) => (
                                            <HStack width={"100%"}>
                                                <Input as={FastField}
                                                       name={`input.filter.${index}`}
                                                       placeholder="Enter sql filter here"
                                                       value={fil}
                                                       type="text"
                                                       size={"sm"}
                                                       width={"100%"}/>
                                                <Button size={"sm"}
                                                        onClick={() => remove(index)}>
                                                    Remove
                                                </Button>
                                            </HStack>
                                        ))}
                                    <Button size={"sm"} onClick={() => push('')}>
                                        Add line
                                    </Button>
                                </Stack>
                            )}
                        </FieldArray>
                    </Box>

                </HStack>
            </Stack>
        </FormControl>
        <Divider/>
        <Flex direction="row-reverse">
            <Button variant="primary" type="submit">Submit</Button>
        </Flex>
    </>
}
