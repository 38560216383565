import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithNavigate from "./auth0-provider-with-navigate";
import {BrowserRouter} from "react-router-dom";
import ApolloProviderWrapper from "./apollo-provider-with-authentication";
import {ChakraProvider} from '@chakra-ui/react'
import {theme} from './theme'
import { Buffer } from 'buffer'
window.Buffer = window.Buffer || Buffer

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode>
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <ApolloProviderWrapper>
                        <App/>
                    </ApolloProviderWrapper>
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </ChakraProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
