import {Box, Flex, FormControl, FormLabel, Input, Select, Stack} from "@chakra-ui/react";
import {FastField} from "formik";
import React from "react";
import { FieldMappingViewProps } from "../FieldMappingView";


interface FixedMappingViewProps extends FieldMappingViewProps {
    inputNamePrefix: (fieldIdx: number) => string
    inputValue: string
}
export default function FixedMappingView(props: FixedMappingViewProps) {
    return <FormControl>
        <Stack spacing={"1em"}>
            <Flex>
                <Box w={"50%"}>
                    <FormLabel>Value</FormLabel>
                    {props.availableValues && props.availableValues.length > 0 ?
                        <>
                            <Select onChange={props.formikOnChange}
                                    value={props.inputValue}
                                    placeholder='Select type'
                                    size={"sm"}
                                    name={props.inputNamePrefix(props.idx)}>
                                {props.availableValues.map(c => <option
                                    key={`${props.formik.targetColumn}-${c}`}>{c}</option>)}
                            </Select>
                        </> :
                        <Input as={FastField}
                               size={"sm"}
                               id={props.inputNamePrefix(props.idx)}
                               name={props.inputNamePrefix(props.idx)}
                               value={props.inputValue}
                               onChange={props.formikOnChange}/>
                    }
                </Box>
            </Flex>
        </Stack>
    </FormControl>
}
