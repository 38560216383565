import * as React from 'react'

import {ColumnDef, createColumnHelper, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {Divider, Heading, Stack, Table, Tbody, Td, Text, Th, Tr} from "@chakra-ui/react";

type VerticalTableProps<T> = {
    data: T[] | T
    rows: ColumnDef<T, any>[]
    header?: (originalRow: T) => string
    title?: string
    asOf?: string
    tableId:string
}

export function SimpleColumns<T>(accessors:string[]): ColumnDef<T, any>[]{
    const columnHelper = createColumnHelper<T>()

    return accessors.map(accessor => {
        // @ts-ignore
        return columnHelper.accessor(accessor, {
            cell: info => info.renderValue(),
            // header: () => ",
            // meta: {isNumeric: true},
        })
    })
}

export default function VerticalTable<T>(props: VerticalTableProps<T>) {
    const table = useReactTable({
        data: Array.isArray(props.data) ? props.data : [props.data],
        columns: props.rows,
        getCoreRowModel: getCoreRowModel(),
        debugTable: true,
    })
    return (
        <Stack borderWidth='1px' borderRadius='lg' p="5">
            {props.title ? <Heading size={"md"}>{props.title}</Heading> : ''}
            {props.asOf ? <Text size={"sm"}>as of {props.asOf}</Text> : ''}
            {props.title || props.asOf ? <Divider/> : ''}
            <Table size="sm">
                <Tbody>
                    {table.getHeaderGroups().map(headerGroup => (
                            headerGroup.headers.map((header, idx) => (
                                <Tr key={props.tableId + headerGroup.id + idx}>
                                    <Th>{flexRender(
                                        header.column.columnDef.header,
                                        header.getContext())}
                                    </Th>
                                    {table.getRowModel().rows.map(row => (
                                        <Td>{flexRender(row.getVisibleCells()[idx].column.columnDef.cell, row.getVisibleCells()[idx].getContext())} </Td>
                                    //     { row.getValue(header.column.id)}
                                    ))}
                                </Tr>
                        )))
                    )}
                </Tbody>
            </Table>
        </Stack>
    )
}
