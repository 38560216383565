import {useMutation} from "@apollo/client";
import {Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack} from "@chakra-ui/react";
import React from "react";
import {NewUnit} from "../../../gql/graphql";
import {useFormik} from "formik";
import {CREATE_UNIT_MUTATION} from "../../generate/CreateMutations";
import {HumanizeCase} from "../../../utils/strings";

export default function CreateUnit() {
    const [submit] = useMutation(CREATE_UNIT_MUTATION);

    const initialValues: NewUnit = {
        address: '',
        city: '',
        state: '',
        company_id: 0,
        create_time: "",
        data: "",
        deleted: false,
        external_property_ref: "",
        external_ref: "",
        id: 0,
        source_id: 0,
        zip: ""
    }


    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (
            values: NewUnit
        ) => {
            submit({variables: {input: values}}).then(value => alert("Success")).catch(value => alert(value))
        }
    });
    type ObjectKey = keyof typeof formik.values;

    const myVar = 'name' as ObjectKey;
    return (
        <Box bg="bg-surface" boxShadow="sm" borderRadius="lg" maxW={{lg: '3xl'}}  py={{ base: '4', md: '8' }}>

            <form onSubmit={formik.handleSubmit}>
                <Stack spacing="5" px={{base: '4', md: '6'}} >
                    <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                        Create Unit
                    </Heading>
                    <Divider/>
                    <Stack spacing="3">
                        {Object.keys(initialValues).map((v, idx) => {
                            const myVar = v as ObjectKey;
                            return (
                                <FormControl id={v}>
                                    <Stack
                                        direction={{ base: 'column', md: 'row' }}
                                        spacing={{ base: '1.5', md: '8' }}
                                        justify="space-between"
                                    >
                                        <FormLabel variant="inline" htmlFor={v}>{HumanizeCase(v)}</FormLabel>
                                        <Input
                                            size={"sm"}
                                            maxW={{ md: '3xl' }}
                                            id={v}
                                            name={v}
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values[myVar] as string}
                                        />
                                    </Stack>
                                </FormControl>
                            )
                        })}

                    </Stack>
                    <Divider/>
                </Stack>
                <Flex direction="row-reverse" py="4" px={{base: '4', md: '6'}}>
                    <Button variant="primary" type="submit">Submit</Button>
                </Flex>
            </form>
        </Box>
    )
}
