import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import {Outlet} from "react-router-dom";
import { Box } from "@chakra-ui/react";

export default function PublicHomeShell() {
    return (
        <>
            <NavBar/>
            <Box as="section" bg="bg-surface">

            <Outlet/>
            </Box>
            <Footer/>
        </>
    )
}
