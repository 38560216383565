import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Code,
    Divider,
    Flex,
    IconButton, Input,
    Spacer,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Tooltip
} from "@chakra-ui/react";
import {FieldMappingInput, FieldMappingType, MappingPairs} from "../../../gql/graphql";
import React from "react";
import AsIsMappingView from "./field/AsIsMappingView";
import ExactMappingView from "./field/ExactMappingView";
import FixedMappingView from "./field/FixedMappingView";
import NowTimestampMappingView from "./field/NowTimestampMappingView";
import ParseStringAsTimestampMappingView from "./field/ParseStringAsTimestampMappingView";
import RegexMappingView from "./field/RegexMappingView";
import {
    GrFormEdit
} from 'react-icons/gr'
import {Icon} from "@chakra-ui/icons";
import {FastField} from "formik";
export interface FieldMappingViewProps {
    targetColumn: string
    sourceColumn: string
    targetType: string
    mappingType: FieldMappingType
    fixedMapping: string
    regexFieldMapping: string
    selectSQLMapping:string
    exactMappingPairs: MappingPairs[]
    availableMapping: FieldMappingType[]
    availableColumns: string[]
    availableValues: string[]
    toolTip?: string

    targetEditable: boolean

    formik: FieldMappingInput
    formikOnChange: (e: React.ChangeEvent<any>) => void

    idx: number
}

export default function FieldMappingView(props: FieldMappingViewProps) {
    let rowCopy: string = ""
    let columnCopy: string = ""
    switch (props.mappingType){
        case FieldMappingType.Fixed:
            rowCopy = `Fixed Value of '${props.fixedMapping}'`
            break
        case FieldMappingType.ValueAsIs:
        case FieldMappingType.IntegerValueAsIs:
        case FieldMappingType.FloatingValueAsIs:
        case FieldMappingType.RegexField:
        case FieldMappingType.ParseStringAsTimestamp:
            rowCopy = `Reading from column:`
            columnCopy = props.sourceColumn
            break
        case FieldMappingType.ExactTyped:
        case FieldMappingType.StartsWithTyped:
        case FieldMappingType.RegexTyped:
            rowCopy = `Exact Dictionary Mapping from:`
            columnCopy = props.sourceColumn
            break
        case FieldMappingType.NowTimestamp:
            rowCopy = "Use current time"
            break
    }
    return <AccordionItem>
        <AccordionButton as={Flex}>
            <Flex w='200px' direction={{ base: 'column', sm: 'row' }}>
                <Tooltip isDisabled={props.toolTip === null} label={<Stack>
                    {props.toolTip?.split("\n").map((l, idx) => <Text key={idx}>{l}</Text>)}
                </Stack>} placement="auto-start">

                    {props.targetEditable ?
                        <Input as={FastField}
                               size={"sm"}
                               id={`input.fieldMappings[${props.idx}].targetColumn`}
                               name={`input.fieldMappings[${props.idx}].targetColumn`}
                               value={props.formik.targetColumn}
                               onChange={props.formikOnChange}
                               w={"60%"}
                        /> :
                        <Text
                            textDecoration="underline"
                            textDecorationStyle="dotted"
                            textUnderlineOffset={".2em"}
                        >{props.targetColumn}</Text>}
                </Tooltip>
            </Flex>
            <Box w='10em'>
                <Text>{rowCopy}</Text>
            </Box>
            <Box w='10em'>
                <Code>{columnCopy}</Code>
            </Box>
            <Spacer flex='2'/>
            <Box flex='1' pl={"1em"}>
                <AccordionIcon/>
            </Box>
        </AccordionButton>

        <AccordionPanel>
            <Box>
                <Tabs variant='enclosed' defaultIndex={props.availableMapping.findIndex((value) => {
                    return props.mappingType == value
                })}>
                    <TabList>
                        {props.availableMapping.map(m => {
                            return <Tab
                                key={m}
                                onClick={() => {
                                props.formik.mappingType = m
                            }}>{m}</Tab>
                        })}
                    </TabList>
                    <TabPanels bg={"white"} rounded={2} border={"1px"} borderColor={"gray.200"}>
                        {props.availableMapping.map(m => {
                            return <TabPanel key={m}>
                                <Stack spacing={"1em"}>
                                <FieldMappingTypeViews type={m} fieldProps={props}/>
                                <Divider/>
                                <Flex direction="row-reverse" >
                                    <Button variant="primary" type="submit">Submit</Button>
                                </Flex>
                                </Stack>
                            </TabPanel>
                        })}
                    </TabPanels>
                </Tabs>
            </Box>
        </AccordionPanel>
    </AccordionItem>
}

export interface FieldMappingTypeViewsProps {
    type: FieldMappingType
    fieldProps: FieldMappingViewProps
}

function FieldMappingTypeViews(props: FieldMappingTypeViewsProps) {
    switch (props.type) {
        case FieldMappingType.Fixed:
            return <FixedMappingView
                key={props.fieldProps.targetColumn}
                inputNamePrefix={(fieldIdx) => `input.fieldMappings[${fieldIdx}].fixedMapping`}
                inputValue={props.fieldProps.formik?.fixedMapping ?? ""}
                {...props.fieldProps}/>
        case FieldMappingType.SelectSql:
            return <FixedMappingView
                key={props.fieldProps.targetColumn}
                inputNamePrefix={(fieldIdx) => `input.fieldMappings[${fieldIdx}].selectSQLMapping`}
                inputValue={props.fieldProps.formik?.selectSQLMapping ?? ""}
                {...props.fieldProps}/>
        case FieldMappingType.RegexField:
            return <RegexMappingView
                key={props.fieldProps.targetColumn}
                inputNamePrefix={(fieldIdx) => `input.fieldMappings[${fieldIdx}].regexFieldMapping`}
                inputValue={props.fieldProps.formik?.regexFieldMapping ?? ""}
                {...props.fieldProps}/>
        case FieldMappingType.NowTimestamp:
            return <NowTimestampMappingView key={props.fieldProps.targetColumn}/>
        case FieldMappingType.ParseStringAsTimestamp:
            return <ParseStringAsTimestampMappingView key={props.fieldProps.targetColumn} {...props.fieldProps}/>
        case FieldMappingType.ValueAsIs:
        case FieldMappingType.IntegerValueAsIs:
        case FieldMappingType.FloatingValueAsIs:
            return <AsIsMappingView key={props.fieldProps.targetColumn} {...props.fieldProps}/>
        case FieldMappingType.RegexTyped:
            return <ExactMappingView
                type={props.type}
                key={props.fieldProps.targetColumn}
                inputNamePrefix={ (fieldIdx:number) => `input.fieldMappings[${fieldIdx}].startsWithTypedMapping`}
                mappingPairs={props.fieldProps.formik.startsWithTypedMapping ?? []}
                label={"Starts Wtih Mapping fields"}
                updatePairs={(pairs) => {
                    props.fieldProps.formik.startsWithTypedMapping = pairs
                }}
                {...props.fieldProps}/>
        case FieldMappingType.StartsWithTyped:
            return <ExactMappingView
                type={props.type}
                key={props.fieldProps.targetColumn}
                inputNamePrefix={ (fieldIdx:number) => `input.fieldMappings[${fieldIdx}].regexTypedMapping`}
                mappingPairs={props.fieldProps.formik.regexTypedMapping ?? []}
                label={"Regex Mapping fields"}
                updatePairs={(pairs) => {
                    props.fieldProps.formik.regexTypedMapping = pairs
                }}
                {...props.fieldProps}/>
        case FieldMappingType.ExactTyped:
            return <ExactMappingView
                type={props.type}
                key={props.fieldProps.targetColumn}
                inputNamePrefix={ (fieldIdx:number) => `input.fieldMappings[${fieldIdx}].exactTypedMapping`}
                mappingPairs={props.fieldProps.formik.exactTypedMapping ?? []}
                label={"Exact Mapping fields"}
                updatePairs={(pairs) => {
                    props.fieldProps.formik.exactTypedMapping = pairs
                }}
                {...props.fieldProps}/>
    }
    return <></>
}
