import React from "react";

export const PageLoader = () => {

    return (
        <div className="loader">
            <span>Loading...</span>
        </div>
    );
};
