import {gql, useQuery} from "@apollo/client";
import {Container, Heading, Stack, Text} from "@chakra-ui/react";
import React from "react";
import {GetGridDataQuery, TableType} from "../../../gql/graphql";
import ExcelGrid from "../../tables/ExcelGrid";

const GET_INCOME = gql`
    query GetIncome($limit: Int!, $offset: Int!) {
        getIncome(limit: $limit, offset: $offset) {
            amount
            company_id
            create_time
            currency
            description
            entered_time
            external_ref
            external_unit_ref
            id
            income_type
            paid_time
            record_time
            source_id
            unit_id
        }
    }
`

const GET_GRID_DATA = gql`
    query GetGridData($limit: Int, $offset: Int, $tableType: TableType!) {
        getGridData(limit: $limit, offset: $offset, tableType: $tableType) {
            columns
            offset
            rows
            tableType
            totalRowCount
        }
    }
`
interface DataExplorerProps {
    type?: TableType
}
export default function DataExplorer(props: DataExplorerProps) {
    const {data, loading, error} = useQuery<GetGridDataQuery>(GET_GRID_DATA, {
        variables: {
            tableType: props.type?? TableType.Unit,
            limit:40,
            offset:0,
        }
    });
    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>
    return <Container>
        <Stack spacing="1">
            <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                Data Explorer
            </Heading>
            <Text color="muted">This is the Data Explorer</Text>
            <ExcelGrid rows={data.getGridData.rows} columns={data.getGridData.columns}/>
        </Stack>
    </Container>
}
