export function HumanizeCase(str: string): string {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
        if (frags[i] === 'Id') {
            frags[i] = 'ID'
        }
    }
    return frags.join(' ');
}
export function DateToHyphenYYYYMMDD(date: Date): string {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 101).toString().substring(1);
    const day = (date.getDate() + 100).toString().substring(1);
    return year + "-" + month + "-" + day;
}
