import {Formik} from "formik";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Box,
    Button, Checkbox,
    Container,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Select,
    Stack,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {GetCompanyListQuery, UpdateImpersonationMutationVariables} from "../../../gql/graphql";
import React from "react";
import AlertTop from "../../notification/AlertTop";

const UPDATE_IMPERSONATION = gql`
    mutation updateImpersonation($companyID: ID!, $demoMode: Boolean!) {
        updateImpersonation(companyID: $companyID)
        updateSetting(demoMode: $demoMode)
    }
`;

const COMPANY_LIST = gql`
    query getCompanyList {
        getCompanyList {
            ID
            name
        }
        currentImpersonation 
        currentDemoMode
    }
`;

export default function Settings() {
    return (
        <Container>
            <AdminSettings/>
        </Container>
    )
}

function AdminSettings() {
    const {
        isOpen: isVisible,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: false })

    const [submit] = useMutation<UpdateImpersonationMutationVariables>(UPDATE_IMPERSONATION, {
        onCompleted: (data)=> {window.location.reload();}
    });
    //
    const {data, loading, error} = useQuery<GetCompanyListQuery>(COMPANY_LIST);

    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>
    return (
        <Box bg="bg-surface" boxShadow="sm" borderRadius="lg" maxW={{lg: '3xl'}} >
            <Formik
                initialValues={{
                    companyID: data?.currentImpersonation,
                    demoMode: data?.currentDemoMode
                }}
                // validateOnChange={false}
                // validateOnBlur={false}
                // validateOnMount={false}
                // enableReinitialize={true}

                onSubmit={(
                    values: UpdateImpersonationMutationVariables
                ) => {
                    console.log("submit", values)
                    submit({variables: values})
                    window.location.reload();
                }}>
                {props => (
            <form onSubmit={props.handleSubmit}>
                <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                    { isVisible && <AlertTop text={"Update success"} onClose={onClose}/>}
                    <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                        Admin Settings
                    </Heading>
                    <Divider/>

                    <Stack spacing="6" direction={{base: 'column', md: 'row'}}>
                        <FormControl id="company-id">
                            <FormLabel htmlFor="company-id">Impersonate Company</FormLabel>
                            <Select
                                onChange={props.handleChange}
                                value={props.values.companyID}
                                placeholder='Select type'
                                size={"sm"}
                                width={"40%"}
                                name={"companyID"}>
                                {data.getCompanyList.map(c => <option key={c.ID} value={c.ID}>{c.ID} - {c.name}</option>)}
                            </Select>
                        </FormControl>

                        <FormControl id="company-id">
                            <FormLabel htmlFor="demo-mode">Demo Mode</FormLabel>
                            <Checkbox
                                onChange={props.handleChange}
                                isChecked={props.values.demoMode}
                                size={"sm"}
                                width={"40%"}
                                name={"demoMode"}/>

                        </FormControl>
                    </Stack>
                </Stack>
                <Divider/>
                <Flex direction="row-reverse" py="4" px={{base: '4', md: '6'}}>
                    <Button variant="primary" type="submit">Submit</Button>
                </Flex>
            </form>
                )}
            </Formik>
        </Box>

    )
}
