import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import {Box, useTheme} from "@chakra-ui/react";
import React from "react";
import {enumToThemeColor, GenericChartProps} from "./ChartProps";

export default function GenericLineChart(props:GenericChartProps) {
    const theme = useTheme();
    return (
        <Box>
            <LineChart width={600} height={240} data={props.data}>
                {props.dataProps.map((prop) => <Line type="monotone" dataKey={prop.dataKey} stroke={enumToThemeColor(prop.color, theme)}/>)}
                <CartesianGrid stroke={theme.colors.gray[150]}/>
                <XAxis dataKey="name" tick={{fontSize: theme.fontSizes["xs"]}}/>
                <YAxis tick={{fontSize: theme.fontSizes["xs"]}}/>
                <Tooltip/>
                <Legend/>
            </LineChart>
        </Box>
    )
}
