import {UnitModel} from "../../gql/graphql";
import GenericTable, {GenericTableProps} from "./GenericTable";
import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import {Link} from "@chakra-ui/react";

const columnHelper = createColumnHelper<UnitModel>()

const unitColumns = [
    columnHelper.accessor(row => row.address, {
        id: 'address',
        header: () => "Address",
        cell: info =>  <Link href={"/app/data-explorer/unit/" + info.row.original.id}>{info.getValue()}</Link>,
        enableGlobalFilter: true,
    }),
    columnHelper.accessor(row => row.state, {
        id: 'state',
        header: () => "State",
        cell: info => info.getValue(),
    }),
    columnHelper.accessor(row => row.city, {
        id: 'city',
        header: () => "City",
        cell: info => info.getValue(),
    }),
    columnHelper.accessor(row => row.bed, {
        id: 'bed',
        header: () => "Bed",
        cell: info => info.getValue(),
        enableGlobalFilter: false,
    }),
    columnHelper.accessor(row => row.bath, {
        id: 'bath',
        header: () => "Bath",
        cell: info => info.getValue(),
        enableGlobalFilter: false,
    }),
    columnHelper.accessor(row => row.year_built, {
        id: 'year_built',
        header: () => "Year Built",
        cell: info => info.getValue(),
        enableGlobalFilter: false,
    }),
    columnHelper.accessor(row => row.external_ref, {
        id: 'external_ref',
        header: () => "External Ref",
        cell: info => info.getValue(),
    }),
]

interface UnitListTableProps extends Omit<GenericTableProps<UnitModel>, "data" | "columns"> {
    unitData: UnitModel[]
}
export default function UnitListTable(props: UnitListTableProps) {
    return <GenericTable<UnitModel>
        data={props.unitData}
        columns={unitColumns}
        filter={true}
        {...props}
    />
}
