import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue, GridItem,
} from '@chakra-ui/react';
import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoSearchSharp,
} from 'react-icons/io5';
import { ReactElement } from 'react';

interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

interface CTAFeaturesProps {
    imageOnLeft?: boolean;
    badge: string;
    header: string;
    desc: string;
    src: string;

}

export default function CTAFeatures({ imageOnLeft, badge, header, desc, src }: CTAFeaturesProps) {
    return (
        <Container maxW={'5xl'} py={12}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}  gridTemplateColumns="repeat(3, 1fr)">
                <Flex order={{ base: 2, md:imageOnLeft ? 1 :  2 }} >
                    <Image
                        rounded={'md'}
                        alt={'feature image'}
                        src={src}
                        objectFit={'cover'}
                    />
                </Flex>
                <GridItem colSpan={2} order={{base: 1, md: imageOnLeft ? 2 : 1}}>
                    <Stack spacing={4}>
                        <Text
                            textTransform={'uppercase'}
                            color={'blue.400'}
                            fontWeight={600}
                            fontSize={'sm'}
                            bg={useColorModeValue('blue.50', 'blue.900')}
                            p={2}
                            alignSelf={'flex-start'}
                            rounded={'md'}>
                            {badge}
                        </Text>
                        <Heading>{header}</Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            {desc}
                        </Text>
                        {/*<Stack*/}
                        {/*    spacing={4}*/}
                        {/*    divider={*/}
                        {/*        <StackDivider*/}
                        {/*            borderColor={useColorModeValue('gray.100', 'gray.700')}*/}
                        {/*        />*/}
                        {/*    }>*/}
                        {/*    <Feature*/}
                        {/*        icon={*/}
                        {/*            <Icon as={IoAnalyticsSharp} color={'yellow.500'} w={5} h={5}/>*/}
                        {/*        }*/}
                        {/*        iconBg={useColorModeValue('yellow.100', 'yellow.900')}*/}
                        {/*        text={'Business Planning'}*/}
                        {/*    />*/}
                        {/*    <Feature*/}
                        {/*        icon={<Icon as={IoLogoBitcoin} color={'green.500'} w={5} h={5}/>}*/}
                        {/*        iconBg={useColorModeValue('green.100', 'green.900')}*/}
                        {/*        text={'Financial Planning'}*/}
                        {/*    />*/}
                        {/*    <Feature*/}
                        {/*        icon={*/}
                        {/*            <Icon as={IoSearchSharp} color={'purple.500'} w={5} h={5}/>*/}
                        {/*        }*/}
                        {/*        iconBg={useColorModeValue('purple.100', 'purple.900')}*/}
                        {/*        text={'Market Analysis'}*/}
                        {/*    />*/}
                        {/*</Stack>*/}
                    </Stack>
                </GridItem>
            </SimpleGrid>

        </Container>
    );
}
