import React from "react";
import {Container, Heading, Stack, Text} from "@chakra-ui/react";
import BarChart from "../views/BarChart";
import LineChart from "../views/LineChart";
import UploadFile from "../inputs/UploadFile";

export default function FileUploadPage() {
    return (
        <Container>
            <Stack spacing="1">
                <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                    Example charts
                </Heading>
                <Text color="muted">These are some example charts</Text>
            </Stack>
            <Stack>
                <UploadFile/>
            </Stack>
        </Container>
    )
}
