import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Select,
    Stack, Tab,
    TabList, TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import ColumnSelect from "./ColumnSelect";
import {FastField, FieldArray} from "formik";
import ExcelGridTyped from "../../../tables/ExcelGridTyped";
import React from "react";
import {FieldMappingViewProps} from "../FieldMappingView";
import {MappingPairsInput} from "../../../../gql/graphql";

export interface ExactMappingViewProps extends FieldMappingViewProps {
    type: string
    inputNamePrefix: (fieldIdx: number) => string
    mappingPairs: MappingPairsInput[]
    updatePairs: (pairs: MappingPairsInput[]) => void
    label: string
}

export default function ExactMappingView(props: ExactMappingViewProps) {
    return <FormControl>
        <Stack spacing={"1em"}>
            <Flex>
                <Box w={"50%"}>
                    <FormLabel>Column</FormLabel>
                    <ColumnSelect {...props}/>
                </Box>
            </Flex>
            <Tabs>
                <TabList>
                    <Tab>Form</Tab>
                    <Tab>Spreadsheet</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <TypedForm {...props}/>
                    </TabPanel>
                    <TabPanel>
                        <ExcelGridTyped
                            updatePairs={props.updatePairs}
                            mappingPairs={props.mappingPairs ?? []}
                            columns={["mapping", "value"]}
                            rowCount={50}
                            values={props.availableValues}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack>
    </FormControl>
}

function TypedForm(props: ExactMappingViewProps) {
    return <Box width={"100%"}>
        <FormLabel maxW={{md: '3xl'}}>{props.label}</FormLabel>
        <FieldArray name={props.inputNamePrefix(props.idx)}>
            {({insert, remove, push}) => (
                <Stack spacing={"1em"}>
                    {props.mappingPairs && props.mappingPairs.length > 0 &&
                        props.mappingPairs.map((ex, index) => {
                                return (
                                    <HStack
                                        width={"100%"}
                                        key={`${props.type}-${props.formik.targetColumn}-${index}`}>
                                        <Input
                                            as={FastField}
                                            name={`${props.inputNamePrefix(props.idx)}.${index}.key`}
                                            placeholder="Enter exact mapping field"
                                            value={ex.key}
                                            type="text"
                                            size={"sm"}
                                            width={"40%"}/>
                                        <Select
                                            onChange={props.formikOnChange}
                                            value={ex.value}
                                            placeholder='Select type'
                                            size={"sm"}
                                            width={"40%"}
                                            name={`${props.inputNamePrefix(props.idx)}.${index}.value`}>
                                            {props.availableValues.map(c => <option>{c}</option>)}
                                        </Select>
                                        <Button
                                            size={"sm"}
                                            width={"20%"}
                                            onClick={() => remove(index)}>
                                            Remove
                                        </Button>
                                    </HStack>)
                            }
                        )}
                    <Button size={"sm"} onClick={() => push({key: "", value: "UNMAPPED"})}>
                        Add line
                    </Button>
                </Stack>
            )}
        </FieldArray>
    </Box>;
}
