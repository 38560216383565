import {gql, useQuery} from "@apollo/client";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Container,
    Divider,
    Flex,
    Heading,
    Link,
    Spacer,
    Stack,
    Tab,
    Table,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";

import React from "react";
import {GetSourceAndMappingQuery, TableType} from "../../../gql/graphql";
import NavButton from "../../layout/NavButton";
import {RxTable} from "react-icons/rx";
import {HumanizeCase} from "../../../utils/strings";

const sourceID = 0

const GET_SOURCES_AND_MAPPING = gql`
    query getSourceAndMapping {
        getSources {
            name
            tables {
                name
                columns
            }
            id
        }
        getMappingInput {
            sourceTableName
            sourceSchemaName
            type
            availableColumns
            filter
            idExternalRefType
            sourceID
        }
    }
`

interface MappingIntegrationsViewProps {
    type?: TableType
}

export default function MappingIntegrationsView(props: MappingIntegrationsViewProps) {
    const {data, loading, error} = useQuery<GetSourceAndMappingQuery>(GET_SOURCES_AND_MAPPING);
    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>

    return (
        <Container>
            <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                Integrations
            </Heading>

        <Tabs>
            <TabList>
                <Tab>
                    View By Integration
                </Tab>
                <Tab>
                    View By Data Type
                </Tab>
                <Tab>
                    Create New
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <Container>
                    <MappingsByIntegrations data={data}/>
                    </Container>
                </TabPanel>
                <TabPanel>
                    <Container>
                    <ByMappings mappingInputs={data.getMappingInput}/>
                    </Container>
                </TabPanel>
                <TabPanel>
                    <Container>
                        <CreateNewMapping/>
                    </Container>
                </TabPanel>
            </TabPanels>
        </Tabs>
        </Container>

    )
}

interface IntegrationsProps {
    data: GetSourceAndMappingQuery
}

export function MappingsByIntegrations(props: IntegrationsProps) {
    return (<Container>
        <Stack spacing="1">

            <Box w="100%" as="section" py={{base: '4', md: '8'}}>
                <Stack spacing={"1em"}>
                    {props.data.getSources.map(v => {
                            return (
                                <Accordion allowMultiple borderRadius="lg">
                                    <AccordionItem bg="bg-surface" boxShadow="sm" borderRadius="lg"
                                                   p={{base: '4', md: '6'}}>
                                        <AccordionButton as={Flex}>
                                            <Box flex='2'>
                                                <Text fontSize="lg" fontWeight="medium">
                                                    {v.name}
                                                </Text>
                                                <Text fontSize="sm" color="muted">
                                                    Some details about {v.name} integration
                                                </Text>
                                            </Box>
                                            <Spacer flex='2'/>
                                            <Box pl={"1em"}>
                                                <AccordionIcon/>
                                            </Box>
                                        </AccordionButton>
                                        <AccordionPanel>
                                            <Box borderWidth={{base: '0', md: '1px'}} p={{base: '0', md: '4'}}
                                                 borderRadius="lg">
                                                <Stack spacing={"5"}>
                                                    <Stack justify="space-between" spacing="2">
                                                        <Text fontWeight={"medium"}>Available Tables</Text>
                                                        <Divider/>
                                                        {v.tables?.map(t => {
                                                            return (
                                                                <Text>{t.name}</Text>
                                                            )
                                                        })}
                                                    </Stack>
                                                    <ByMappings mappingInputs={props.data?.getMappingInput?.filter(input => input.sourceID == v.id)}/>
                                                </Stack>
                                            </Box>


                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            )
                        }
                    )}
                </Stack>
            </Box>
        </Stack>
    </Container>)
}

interface MappingInputsProps {
    mappingInputs: Array<{ sourceTableName: string, sourceSchemaName: string, type: TableType, idExternalRefType: string, sourceID: string}>
}
export function ByMappings(props: MappingInputsProps) {
    return (
        <Stack justify="space-between" spacing="2">
            <Table>
                <Thead>
                <Tr>
                    <Th>Data Type</Th>
                    <Th>Source Table</Th>
                    <Th>Integration Source</Th>
                    <Th>External ID Ref</Th>
                </Tr>
                </Thead>
                <Tbody>
                {props.mappingInputs.map(input => {
                    return (<Tr>
                            <Td><Link href={`/app/mapping/mapping_id/${input.type}/${input.sourceID}`}>{input.type}</Link></Td>
                            <Td>{input.sourceTableName}</Td>
                            <Td>{input.sourceSchemaName}</Td>
                            <Td>{input.idExternalRefType}</Td>
                        </Tr>
                    )
                })}
                </Tbody>
            </Table>
        </Stack>
    )
}

function CreateNewMapping() {
    return (<Stack>
        {Object.values(TableType).map(
            v => {
                return <Link href={`mapping/${v.toLowerCase()}/create`} size={"sm"}>{HumanizeCase(v)}</Link>
            })}
    </Stack>)
}
