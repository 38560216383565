// Code generated by go generate; DO NOT EDIT.

import {gql} from "@apollo/client";


export const CREATE_UNIT_MUTATION = gql`
    mutation createUnit($input: NewUnit!) {
        createUnit(input: $input, )
    }
`
export const CREATE_PROPERTY_MUTATION = gql`
    mutation createProperty($input: NewProperty!, $unit_id: ID!) {
        createProperty(input: $input, unit_id: $unit_id)
    }
`
export const CREATE_LEASE_MUTATION = gql`
    mutation createLease($input: NewLease!, $unit_id: ID!) {
        createLease(input: $input, unit_id: $unit_id)
    }
`
export const CREATE_INCOME_MUTATION = gql`
    mutation createIncome($input: NewIncome!, $unit_id: ID!) {
        createIncome(input: $input, unit_id: $unit_id)
    }
`
export const CREATE_EXPENSE_MUTATION = gql`
    mutation createExpense($input: NewExpense!, $unit_id: ID!) {
        createExpense(input: $input, unit_id: $unit_id)
    }
`
export const CREATE_EVENT_MUTATION = gql`
    mutation createEvent($input: NewEvent!, $unit_id: ID!) {
        createEvent(input: $input, unit_id: $unit_id)
    }
`

