import {Box} from "@chakra-ui/react";
import {Column, HeaderCell, NumberCell, ReactGrid, Row, TextCell} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";

interface ExcelGridProps{
    columns: string[]
    rows: string[][]
}
export default function ExcelGrid(props: ExcelGridProps) {
    const rows = getExcelRows(props.rows, props.columns);
    const columns = getExcelColumns(props.columns);
    return (
        <Box borderWidth='1px' borderRadius='lg' p="5" overflowY={"scroll"}>
            <ReactGrid enableRangeSelection enableColumnSelection enableRowSelection rows={rows} columns={columns}/>
        </Box>
    );
}

const getExcelColumns = function (cols: string[]): Column[] {
    return [
        ...cols.map((col) => {
            return {columnId: col, width: 150}
        })
    ]
}

const getExcelRows = function (rows: string[][], cols: string[]): Row[] {
    const headerRow: Row = {
        rowId: "header",
        cells: [
            ...cols.map((col): HeaderCell => {
                return {type: "header", text: col, nonEditable:true}
            })
        ]
    }
    return [
        headerRow,
        ...rows.map((col, idx): Row => {
                return {
                    rowId: idx,
                    cells: [
                        ...col.map((val): TextCell | NumberCell => {
                            switch (typeof val) {
                                case "number":
                                    return {type: "number", value: val, nonEditable:true}
                                default:
                                    return {type: "text", text: val, nonEditable:true}
                            }
                        })
                    ]
                }
            }
        )
    ]
}
