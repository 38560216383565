import {Avatar, Box, Flex, FlexProps, HStack, Icon, Link, Stack, Text, useColorModeValue} from '@chakra-ui/react'
import {FaLinkedin} from 'react-icons/fa';

interface CardWithAvatarProps extends FlexProps {
  name: string
  title: string
  bio: string
  linkedIn: string
  src: string
}

export const CardWithAvatar = (props: CardWithAvatarProps) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      rounded="md"
      padding="8"
      position="relative"
      bg={useColorModeValue('white', 'gray.700')}
      shadow={{ md: 'base' }}
    >
      <Box position="absolute" inset="0" height="20" bg="blue.600" roundedTop="inherit" />
      <Avatar size="2xl" src={props.src} name={props.name}/>
      <Stack spacing="1" flex="1">
        <HStack>
          <Text size={'lg'} fontWeight="bold">{props.name}</Text> <Link href={props.linkedIn}><Icon as={FaLinkedin} w={5} h={5}/></Link>
        </HStack>
        <Text fontWeight="bold">{props.title}</Text>
        <Text
            fontSize="md"
            whiteSpace="pre-wrap"
            color={useColorModeValue('gray.600', 'gray.400')}
        >
          {props.bio}
        </Text>
      </Stack>
    </Flex>
  )
}
