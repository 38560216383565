import {withAuthenticationRequired} from "@auth0/auth0-react";
import React from "react";
import {PageLoader} from "../pages/PageLoader";

interface AuthenticationGuardProps {
    element: JSX.Element;
}
export default  function AuthenticationGuard ( {element}:AuthenticationGuardProps ) {
    const vc: React.FC = () => element
    const Component = withAuthenticationRequired(vc, {
        onRedirecting: () => (
            <div className="page-layout">
                <PageLoader />
            </div>
        ),
    });

    return <Component />;
};
