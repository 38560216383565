import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts';
import {Box, useTheme} from "@chakra-ui/react";
import React from "react";
import {enumToThemeColor, GenericChartProps} from "./ChartProps";
export default function GenericBarChart(props:GenericChartProps) {
    const theme = useTheme();
    return (
        <Box>
            {/*ordering matters in terms of what gets shown first*/}
            <BarChart width={600} height={240} data={props.data}>
                <Tooltip/>
                {props.dataProps.map((prop) => <Bar dataKey={prop.dataKey} barSize={15} fill={enumToThemeColor(prop.color, theme)}/>)}
                <CartesianGrid stroke={theme.colors.gray[150]}/>
                <XAxis dataKey="name" tick={{fontSize: theme.fontSizes["xs"]}}/>
                <YAxis tick={{fontSize: theme.fontSizes["xs"]}}/>
                <Legend/>
            </BarChart>
        </Box>
    )
}
