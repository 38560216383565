import {gql, useQuery} from "@apollo/client";
import {Container, Heading, Stack, Text} from "@chakra-ui/react";
import React from "react";
import {useParams} from "react-router-dom";
import {GetAllUnitsQuery, UnitModel} from "../../../gql/graphql";
import UnitListTable from "../../tables/UnitModelTable";

const GET_ALL_UNITS = gql`
    query getAllUnits {
        getAllUnits {
            address
            external_ref
            id
            state
            city
            year_built
            bed
            bath
        }
    }
`

export default function BrowseUnit() {
    let { unit_id } = useParams();
    const {data, loading, error} = useQuery<GetAllUnitsQuery>(GET_ALL_UNITS);
    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>
    if (!data.getAllUnits) return <Text>Data not found...</Text>
    return <Container>
        <Stack spacing="1">
            <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                Portfolio of Units
            </Heading>
            <Text color="muted">This is the list of units</Text>
            <UnitListTable
                unitData={(data.getAllUnits as UnitModel[])}
                filter={true}
            />
        </Stack>
    </Container>
}
