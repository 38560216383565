import {gql, useQuery} from "@apollo/client";
import {Box, Container, Flex, Heading, Spacer, Stack, Text} from "@chakra-ui/react";
import {createColumnHelper} from "@tanstack/react-table";
import React from "react";
import {useParams} from "react-router-dom";
import {
    EventModel,
    ExpenseModel,
    GetUnitDataQuery,
    GetUnitDataQueryVariables,
    IncomeModel, LeaseModel,
    LineItemModel,
    NoiTimePeriodModel,
    TableType,
    UnitModel
} from "../../../gql/graphql";
import VerticalTable, {SimpleColumns} from "../../tables/VerticalTable";
import GenericTable from "../../tables/GenericTable";

const GET_UNITS = gql`
    query GetUnitData($unitID: ID!) {
        getUnitData(unitID: $unitID) {
            address
            bath
            bed
            city
            create_time
            data
            external_property_ref
            external_ref
            id
            property_id
            source_id
            sqft
            state
            unit_type
            year_built
            zip
            income {
                income_type
                record_time
                amount
            }
            expense {
                expense_type
                record_time
                amount
            }
            noi {
                expenseLineItems {
                    title
                    amount
                    subLineItem {
                        title
                        amount
                    }
                }
                expenseSubLineItems {
                    title
                    amount
                }
                revenueLineItems {
                    title
                    amount
                    subLineItem {
                        title
                        amount
                    }
                }
                revenueSubLineItems {
                    title
                    amount
                }
                timePeriod
            }
            leases {
                start_date
                end_date
                rent
            }
            events {
                event_type
                record_date
            }
        }
    }
`

interface SearchUnitProps {
    type?: TableType
}

const columnHelper = createColumnHelper<IncomeModel>()

const columnsIncome = [
    columnHelper.accessor(row => row.record_time, {
        id: 'record_time',
        cell: info => <b>{info.getValue()}</b>,
    }),
    columnHelper.accessor(row => row.income_type, {
        id: 'income_type',
        cell: info => info.getValue(),
    }),
    columnHelper.accessor(row => row.amount, {
        id: 'amount',
        cell: info => info.getValue(),
    }),
]

const expenseColumnHelper = createColumnHelper<ExpenseModel>()

const columnsExpense = [
    expenseColumnHelper.accessor(row => row.record_time, {
        id: 'record_time',
        cell: info => <b>{info.getValue()}</b>,
    }),
    expenseColumnHelper.accessor(row => row.expense_type, {
        id: 'expense_type',
        cell: info => info.getValue(),
    }),
    expenseColumnHelper.accessor(row => row.amount, {
        id: 'amount',
        cell: info => info.getValue(),
    }),
]

const leaseColumnHelper = createColumnHelper<LeaseModel>()

const columnsLease = [
    leaseColumnHelper.accessor(row => row.start_date, {
        id: 'start date',
        cell: info => <b>{info.getValue()}</b>,
    }),
    leaseColumnHelper.accessor(row => row.end_date, {
        id: 'end date',
        cell: info => info.getValue(),
    }),
    leaseColumnHelper.accessor(row => row.rent, {
        id: 'amount',
        cell: info => info.getValue(),
    }),
]

const eventColumnHelper = createColumnHelper<EventModel>()

const columnsEvent = [
    eventColumnHelper.accessor(row => row.record_date, {
        id: 'record date',
        cell: info => <b>{info.getValue()}</b>,
    }),
    eventColumnHelper.accessor(row => row.event_type, {
        id: 'event type',
        cell: info => info.getValue(),
    }),
]

const noiColumnHelper = createColumnHelper<LineItemModel>()

const noiRows = [
    noiColumnHelper.accessor(row => row.title, {
        id: 'Title',
        cell: info => <b>{info.getValue()}</b>,
    }),
    noiColumnHelper.accessor(row => row.amount, {
        id: 'Amount',
        cell: info => info.getValue(),
    }),
]
export default function UnitView(props: SearchUnitProps) {
    let {unit_id} = useParams();
    const {data, loading, error} = useQuery<GetUnitDataQuery, GetUnitDataQueryVariables>(GET_UNITS, {
        variables: {
            unitID: unit_id ?? "",
        }
    });
    if (loading) return <Text>Loading...</Text>
    if (error) return <Text>{error.message}</Text>
    if (!data) return <Text>Data not found...</Text>
    if (!data.getUnitData) return <Text>Data not found...</Text>
    console.log(data.getUnitData.noi)
    return <Container>
        <Stack spacing="2em">
            <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                Unit Details
            </Heading>
            <Flex>
                <Box flex='1'>
                    <VerticalTable<UnitModel>
                        data={data.getUnitData as UnitModel}
                        rows={SimpleColumns<UnitModel>(
                            ['address', 'city', 'state', 'zip', 'bed', 'bath', 'sqft', 'year_built', 'external_ref'])}
                        title={"Basic Data"}
                        asOf={data.getUnitData.create_time}
                        tableId={`${unit_id}-${new Date().valueOf()}-static-data`}
                    />
                </Box>
                <Spacer/>
                <Box flex='2' borderWidth='1px' borderRadius='lg' p="5">
                    <iframe
                        width="600"
                        height="450"
                        // style="border:0"
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&q=${data.getUnitData.address}-${data.getUnitData.city}+${data.getUnitData.state}`}>
                    </iframe>
                </Box>
            </Flex>

            <GenericTable data={(data.getUnitData.leases as LeaseModel[])} columns={columnsLease}
                          title={"Lease History"}/>
            <GenericTable data={(data.getUnitData.events as EventModel[])} columns={columnsEvent}
                          title={"Event History"}/>
            <NOITable data={data.getUnitData.noi as NoiTimePeriodModel[]} title={"NOI"}/>
            <GenericTable data={(data.getUnitData.income as IncomeModel[])} columns={columnsIncome}
                          title={"Income History"}/>
            <GenericTable data={(data.getUnitData.expense as ExpenseModel[])} columns={columnsExpense}
                          title={"Expense History"}/>
        </Stack>
    </Container>
}

interface NOITableProps {
    title: string
    data: NoiTimePeriodModel[]
}

interface UILineItem {
    line: string
    items: string[]
    subitems: UILineItem[]
    isSubtotal: boolean
}

function NOITable(props: NOITableProps) {
    const noiColumnHelper = createColumnHelper<UILineItem>()

    let noiRows = [
        noiColumnHelper.accessor(row => row.line, {
            id: 'Title',
            cell: ({row, getValue}) => (
                <Text
                    pl={
                        // Since rows are flattened by default,
                        // we can use the row.depth property
                        // and paddingLeft to visually indicate the depth
                        // of the row
                        `${row.depth * 2}rem`
                    }
                    // href={`/list-view/${row.getValue("value1")}`}
                >
                    <>
                        {' '}
                        {row.getCanExpand() ? (
                            <button
                                {...{
                                    onClick: row.getToggleExpandedHandler(),
                                    style: {cursor: 'pointer'},
                                }}
                            >
                                {row.getIsExpanded() ? '➖' : '➕'}
                            </button>
                        ) : (
                            ' '
                        )}{' '}
                        {
                            getValue()
                        }
                    </>
                </Text>),
            header: '',
        }),
    ]

    noiRows = noiRows.concat(props.data.map(
        (r, idx) => {
            return noiColumnHelper.accessor(row => row.items[idx], {
                id: `Amount-${idx}`,
                cell: info => info.cell.row.original.isSubtotal ? <b>{info.getValue()}</b> : info.getValue(),
                meta: {isNumeric: true},
                header: r.timePeriod,
            })
        }
    ))
    const width = props.data.length * 10 + 20

    return (
        <Box width={`${width}em`}>
            <GenericTable
                subrows={row => row.subitems}
                size={"sm"}
                data={CreateNOILineItems(props.data)}
                columns={noiRows}
                title={props.title}/>
        </Box>
    )
}

function CreateNOILineItems(timePeriodModel: NoiTimePeriodModel[]): UILineItem[] {
    let result: UILineItem[] = []
    timePeriodModel.forEach((m, idx) => {
        let resultIdx = 0;
        ({result, resultIdx} = updateLineItemArrayAndIdx(result, resultIdx, false, ...m.revenueLineItems));
        ({result, resultIdx} = updateLineItemArrayAndIdx(result, resultIdx, true, m.revenueSubLineItems));
        ({result, resultIdx} = updateLineItemArrayAndIdx(result, resultIdx, false, {title: ''} as LineItemModel));
        ({result, resultIdx} = updateLineItemArrayAndIdx(result, resultIdx, false, ...m.expenseLineItems));
        ({result, resultIdx} = updateLineItemArrayAndIdx(result, resultIdx, true, m.expenseSubLineItems));
    })
    return result
}

function updateLineItemArrayAndIdx(
    result: UILineItem[],
    idx: number,
    isSubTitle: boolean,
    ...items: LineItemModel[]
): {result: UILineItem[], resultIdx: number} {
    items.forEach(li => {

        if (result.length === idx) {
            let subItems: UILineItem[] = []
            if (li.subLineItem && li.subLineItem.length > 0) {
                subItems = li.subLineItem.map(sli => {
                    return {
                        line: sli.title,
                        items: [],
                        isSubtotal: false,
                        subitems: [],
                    } as UILineItem
                })
            }
            result[idx] = {
                isSubtotal: isSubTitle,
                line: "",
                subitems: subItems,
                items: []
            }
        }
        li.subLineItem?.forEach((sli,subIdx) => {
            result[idx].subitems[subIdx].items.push(sli.amount.toString())
        })
        result[idx].line = li.title
        result[idx].items.push(li.amount)
        idx++
    })
    let resultIdx = idx
    return {result, resultIdx}
}
