import {Icon} from '@chakra-ui/icons'
import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Progress,
    Stack,
    Text,
} from '@chakra-ui/react'
import {
    FiBarChart2,
    FiBookmark,
    FiCheckSquare, FiDatabase,
    FiGrid,
    FiHelpCircle,
    FiHome,
    FiSearch,
    FiSettings,
    FiUsers,
} from 'react-icons/fi'
import {
    AiOutlinePartition
} from 'react-icons/ai'
import NavButton from './NavButton'
import UserProfile from './UserProfile'
import {useAuth0} from "@auth0/auth0-react";
import LogoutButton from "../authentication/LogoutButton";
import React from "react";
import {Logo} from "../assets/Logo";
import Form from "../pages/Form";
import {useMatch} from "react-router-dom";
import {TableType} from "../../gql/graphql";
import {RxTable} from "react-icons/rx";
import {IoCreateOutline} from 'react-icons/io5'
import {HumanizeCase} from "../../utils/strings";
import {MdOutlineAnalytics} from "react-icons/md";


export default function Sidebar() {
    const {user} = useAuth0();

    return (
        <Flex
            flex="1"
            bg="bg-accent"
            color="on-accent"
            overflowY="auto"
            maxW={{base: 'full', sm: 'xs'}}
            py={{base: '6', sm: '8'}}
            px={{base: '4', sm: '6'}}
        >
            <Stack justify="space-between" spacing="1">
                <Stack spacing={{base: '5', sm: '6'}} shouldWrapChildren>
                    <Logo color="on-accent"/>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <Icon as={FiSearch} color="on-accent" boxSize="5"/>
                        </InputLeftElement>
                        <Input placeholder="Search" variant="filled" colorScheme="blue"/>
                    </InputGroup>
                    <Stack spacing="1">
                        <NavButton label="Home" to="/app" icon={FiHome} end/>
                        <NavButton label="Development" to="dev" icon={FiGrid}/>
                        {useMatch("/app/dev/*") ?
                            <Stack paddingLeft={"2rem"} spacing="0">
                                <NavButton label="Tables" to="dev/tables" icon={FiGrid}/>
                                <NavButton label="Charts" to="dev/charts" icon={FiBarChart2}/>
                                <NavButton label="Form" to="dev/form" icon={FiCheckSquare}/>
                                <NavButton label="Upload" to="dev/file-upload" icon={FiBookmark}/>
                            </Stack> : <></>}

                        <NavButton label="Workflows" to="workflow" icon={IoCreateOutline} end/>
                        {useMatch("/app/workflow/*") ?
                            <Stack paddingLeft={"2rem"} spacing="0">
                                <NavButton label={"Create Unit"} to={"workflow/create-unit"} icon={FiGrid} size={"sm"}/>
                            </Stack> : <></>}

                        <NavButton label="Integrations And Mappings" to="mapping" icon={AiOutlinePartition} end/>
                        {useMatch("/app/mapping/*") ?
                            <Stack paddingLeft={"2rem"} spacing="0">
                                <NavButton label={"ID Map"} to={"mapping/id-map"} icon={FiGrid} size={"sm"}/>

                            </Stack> : <></>}
                        <NavButton label="Data Explorer" to="data-explorer" icon={FiDatabase} end/>
                        {useMatch("/app/data-explorer/*") ?
                            <Stack paddingLeft={"2rem"} spacing="0">
                                {Object.values(TableType).map(
                                    v => {
                                        return <NavButton key={v} label={v} to={"data-explorer/" + v.toLowerCase()}
                                                          icon={RxTable} size={"sm"}/>
                                    })}
                            </Stack> : <></>}
                        <NavButton label="Portfolio" to="portfolio" icon={MdOutlineAnalytics} end/>

                        <NavButton label="Users" to="test4" icon={FiUsers}/>x
                    </Stack>
                </Stack>
                <Stack spacing={{base: '5', sm: '6'}}>
                    <Stack spacing="1">
                        <NavButton label="Help" to="test5" icon={FiHelpCircle}/>
                        <NavButton label="Settings" to="settings" icon={FiSettings}/>
                    </Stack>
                    <Box bg="bg-accent-subtle" px="4" py="5" borderRadius="lg">
                        <Stack spacing="4">
                            <Stack spacing="1">
                                <Text fontSize="sm" fontWeight="medium">
                                    Almost there
                                </Text>
                                <Text fontSize="sm" color="on-accent-muted">
                                    Fill in some more information about you and your person.
                                </Text>
                            </Stack>
                            <Progress
                                value={80}
                                size="sm"
                                variant="on-accent"
                                aria-label="Profile Update Progress"
                            />
                            <HStack spacing="3">
                                <Button variant="link-on-accent" size="sm" color="on-accent-muted">
                                    Dismiss
                                </Button>
                                <Button variant="link-on-accent" size="sm">
                                    Update profile
                                </Button>
                            </HStack>
                        </Stack>
                    </Box>
                    <Divider borderColor="bg-accent-subtle"/>
                    <UserProfile
                        name={user?.name ?? ""}
                        image={user?.picture ?? ""}
                        email={user?.email ?? ""}
                    />
                    <LogoutButton variant="ghost-on-accent"/>
                </Stack>
            </Stack>
        </Flex>
    )
}
