import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Button, ButtonProps} from "@chakra-ui/react";

export default function LogoutButton(props: ButtonProps) {
    const { logout } = useAuth0();
    return (
        <Button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} {...props}>
            Log Out
        </Button>
    );
};
