import {
    Box,
    Button,
    Center,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    Link,
    SimpleGrid,
    Stack,
    Text,
    VStack
} from '@chakra-ui/react'
import CTATop, {FeatureProps} from "./CTATop";
import CTAFeatures from "./CTAFeatures";

import {FcBullish, FcIdea, FcList} from 'react-icons/fc';
import {InfoEmailAddress, profileData} from "./Common";
import {CardWithAvatar} from "./team/CardWithAvatar";

import MailchimpSubscribe, {EmailFormFields} from "react-mailchimp-subscribe"
import CustomForm from "./Subscribe";

const headerTagLine = "Easily use data to improve real estate returns"
const subheaderTagLine = "Our purpose-built data integrations and analytics platform " +
    "for residential real estate investors turns your data into your competitive advantage"
const backgroundImg = 'https://images.unsplash.com/photo-1571979622878-622d38ec238c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1759&q=80'

// Top CTA
const title1 = 'Smart Data Ingestion'
const title2 = 'Scalable Data Normalization'
const title3 = 'Automated Validation and Auditing'
const title4 = 'Seamless Data Delivery'
const title5 = 'Power your Data Analytics'

const shortDesc1 = 'Overcome silohed data across your portfolio and teams. We handle the extraction and aggregation of the data you need'
const shortDesc2 = 'Mapping data across systems can be painful both inside and outside of a data warehouse, our enterprise-grade data models normalize your data'
const shortDesc3 = 'The residential real estate ecosystem is complicated and involves many stakeholder, data integrity is challenged. We audit your data for you'
const shortDesc4 = 'Our goal is to empower your organization with data, so we make sure to deliver your data in a granular and ready to use format'
const shortDesc5 = 'No longer worry about the quality of your data, we jump start your revenue optimization and expense management capabilities with trusted data'

// Mid CTA

const heading1 = 'All Data Aggregated for Your Team'
const heading2 = 'All Data Organized in a Digestable Format'
const heading3 = 'All Data Validated and Audited'
const heading4 = 'All Data Delivered and Ready to Go'
const heading5 = 'All Data Prepared for Analysis and Analytics'

const src1 = 'https://images.unsplash.com/photo-1581094289810-adf5d25690e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
const src2 = 'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
const src3 = 'https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1106&q=80'
const src4 = 'https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1106&q=80'
const src5 = 'https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1106&q=80'

const desc1 = 'While taking an API first approach to the systems used in the residential ecosystem, we also support SFTP. We know Real Esate, and support Excels, CSVs, PDFs and more!' +
      'Our platform utilizes universal connectivity to seamlessly integrate with your existing systems, ' +
    'and can just as seamlessly ingest csv, excel or pdf formats from your third party vendors. ' +
    'Dont waste time copying and pasting files from different sources or filtering through excels. ' +
    'With seamless data integreations and ingestion, gain access to your data in real-time. '
    
const desc2 = "From Underwriting to Disposition, every lifecycle segment in residential real estate " +
    "has a system or unique data source, creating a disparate system of silohed data. " +
    "Once the data is in one place, the data set needs to be mapped appropriately so that your team" +
    "can access all this information at the most granular levels from one place. Our Data models will ensure data is matched" +
    "across systems and vendors to ensure you can trust what your data is telling you."

const desc3 = 'From blank data fields to incorrect inputs, data needs to be audited and  ' +
    'fixed before teams can report and use this data to make operational and financial decisions.'+
      ' By surfacing these data inconsistencies to your team in real-time, incorrect data can be corrected'+
      'proactively. Gain more trust in your data through our library of audits and alerts. '

const desc4 = 'You need all data to fit into your infrastructure, so we deliver data via API or CSV ' +
    'directly into your data warehouse. You maintain ownership of all your data, but remove the headache'+
      ' of all the back-end work to get to a usable and trusted data set.'+
      'In case you need it, we have pre-set reports that you can access to gain insights into operational and financial' +
      'data from portfolio to unit level.'

const desc5 = 'Unlock the true value in your data through reporting and analytics to optimize returns.'  +
    'Your trusted and comprehensive data will allow you to improve revenue and expense management.' +
      'Look at your data through a historical context, or click through from portfolio level information to '+
      'unit level. Even take a look at how historical financils are tracking to budget - all from one source!'

const feature1: FeatureProps = {
    title: title1,
    text: shortDesc1,
    icon: <Icon as={FcIdea} w={10} h={10}/>
}

const feature2: FeatureProps = {
    title: title2,
    text: shortDesc2,
    icon: <Icon as={FcBullish} w={10} h={10}/>
}

const feature3: FeatureProps = {
    title: title3,
    text: shortDesc3,
    icon: <Icon as={FcList} w={10} h={10}/>
}

const feature4: FeatureProps = {
    title: title4,
    text: shortDesc4,
    icon: <Icon as={FcIdea} w={10} h={10}/>
}

const feature5: FeatureProps = {
    title: title5,
    text: shortDesc5,
    icon: <Icon as={FcList} w={10} h={10}/>
}

const bottomCTAFeature1: FeatureProps = {
    title: "For institutions",
    text: "Manage your portfolio effectively with our purpose built solution. Scale from hundreds of homes to tens of thousands with Resitech",
    largeTitle: true
}

const bottomCTAFeature2: FeatureProps = {
    title: "For property managers",
    text: "Provide data operations ease with Resitech. See what your investors are looking for even before they do.",
    largeTitle: true
}

const bottomCTAFeature3: FeatureProps = {
    title: "For proptechs",
    text: "Enable more with our API solution and work with our industry operational and data standards",
    largeTitle: true
}

interface PublicHomeProps {
    mailchimpUrl: string
}

export default function PublicHome(props: PublicHomeProps) {
    return (
        <Container py={{base: '16', md: '24'}}>
            <Stack spacing={{base: '8', md: '10'}}>
                <HStack spacing={{base: '4', md: '5'}}>
                    <Stack spacing={{base: '6', md: '8'}}>
                        <Heading size={{base: 'sm', md: 'lg'}}>{headerTagLine}</Heading>
                        <Text color="muted" maxW="2xl" fontSize="xl">
                            {subheaderTagLine}
                        </Text>
                        <Stack spacing="4">
                            <Text fontSize="sm" fontWeight="semibold" color="muted">
                                Share your email and we will reach out
                            </Text>
                            <MailchimpSubscribe
                                url={props.mailchimpUrl}
                                render={({subscribe, status, message}) => (
                                    <CustomForm
                                        status={status}
                                        message={message}
                                        onValidated={(formData: EmailFormFields) => subscribe(formData)}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Flex
                        w={'100vh'}
                        h={'80vh'}
                        backgroundImage={
                            backgroundImg
                        }
                        backgroundSize={'cover'}
                        backgroundPosition={'center center'}/>
                </HStack>
                <VStack spacing={{base: '10', md: '14'}}>
                    <CTATop feature1={feature1} feature2={feature2} feature3={feature3} feature4={feature4}
                            feature5={feature5}/>
                    <Divider/>
                    <CTAFeatures badge={title1} header={heading1} desc={desc1} imageOnLeft={true} src={src1}/>
                    <Divider/>
                    <CTAFeatures badge={title2} header={heading2} desc={desc2} src={src2}/>
                    <Divider/>
                    <CTAFeatures badge={title3} header={heading3} desc={desc3} imageOnLeft={true} src={src3}/>
                    <Divider/>
                    <CTAFeatures badge={title4} header={heading4} desc={desc4} src={src3}/>
                    <Divider/>
                    <CTAFeatures badge={title5} header={heading5} desc={desc5} imageOnLeft={true} src={src3}/>
                    <Divider/>
                    <CTATop feature1={bottomCTAFeature1} feature2={bottomCTAFeature2} feature3={bottomCTAFeature3}/>
                    <Divider/>
                    <Center>
                        <Heading size={{base: 'sm', md: 'lg'}}>Meet our team</Heading>
                    </Center>
                    <Box as="section" maxW={{base: 'xs', md: '3xl'}} mx="auto">
                        <SimpleGrid columns={{base: 1, md: Math.min(profileData.length, 3)}} spacing="6">
                            {profileData.map((user) => {
                                return (
                                    <CardWithAvatar key={user.name} name={user.name} title={user.title} bio={user.bio}
                                                    linkedIn={user.linkedIn} src={user.src}/>
                                )
                            })}
                        </SimpleGrid>
                    </Box>
                    <Divider/>
                    <Stack spacing="3" direction={{base: 'column', sm: 'row'}} justify="center">
                        <Button as={Link} href={'mailto:' + InfoEmailAddress} variant="primary" size="lg">
                            Learn more. Contact Us.
                        </Button>
                    </Stack>
                </VStack>
            </Stack>
        </Container>
    )
}
