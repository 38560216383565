import {gql, useMutation} from "@apollo/client";
import React, {useRef} from "react";
import {useFormik} from "formik";
import {Button, Center, Divider, HStack, Icon, Square, Text, useColorModeValue, VStack} from "@chakra-ui/react";
import {FiUploadCloud} from "react-icons/fi";

const MUTATION = gql`
    mutation singleUpload($file: Upload!) {
        singleUpload(file: $file) {
            id
        }
    }
`;

export default function UploadFile() {
    const [fileUpload] = useMutation(MUTATION, {
        onCompleted: (data) => console.log(data),
    });
    const [, setDragActive] = React.useState(false);
    const initialValues: File[] = new Array<File>();
    const formik = useFormik({
        initialValues: {dataFile: initialValues},
        onSubmit: (values: { dataFile: File[] }, {resetForm}) => {
            if (values.dataFile.length > 0) {
                const file = values.dataFile[0]
                fileUpload({variables: {file}}).then(
                    () => {
                        resetForm()
                        alert("upload complete")
                    }
                )
            }
        }
    });
    const inputRef = useRef<HTMLInputElement>(null);
    const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <input
                type="file"
                name="file"
                id="file"
                ref={inputRef}
                onChange={(event) => {
                    const files = event.target.files;
                    if (files) {
                        let myFiles = Array.from(files);
                        formik.setFieldValue("dataFile", myFiles);
                    }
                }}
                hidden
            />
            <Center
                borderWidth="1px"
                borderRadius="lg"
                px="6"
                py="4"
                bg={useColorModeValue('white', 'gray.800')}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDragActive(false);
                    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                        const files = e.dataTransfer.files;
                        if (files) {
                            formik.setFieldValue("dataFile", Array.from(files))
                        }
                    }
                }}
            >
                <VStack spacing="3">
                    <Square size="10" bg="bg-subtle" borderRadius="lg">
                        <Icon as={FiUploadCloud} boxSize="5" color="muted"/>
                    </Square>
                    <VStack spacing="1">
                        <HStack spacing="1" whiteSpace="nowrap">
                            <Button variant="link" colorScheme="blue" size="sm"
                                    onClick={() => inputRef?.current?.click()}>Choose file</Button>
                            <Text fontSize="sm" color="muted">
                                or drag and drop
                            </Text>
                        </HStack>
                        <Text fontSize="sm">{formik.values.dataFile[0]?.name}</Text>
                    </VStack>
                    <Divider/>
                    <Button variant="primary" type="submit">Submit</Button>
                </VStack>
            </Center>
        </form>
    );
};
