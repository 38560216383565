import React from 'react';
import Home from "./components/pages/Home";
import {Route, Routes} from "react-router-dom";
import NotFound from "./components/pages/NotFound";
import PublicHomeShell from "./components/public-pages/PublicHomeShell";
import AuthenticationGuard from "./components/authentication/AuthenticationGuard";
import AppShell from "./components/layout/AppShell";
import TestPage from "./components/pages/TestPage";
import Tables from "./components/pages/Tables";
import Charts from "./components/pages/Charts";
import Form from "./components/pages/Form";
import FileUploadPage from "./components/pages/FileUploadPage";
import PublicHome from "./components/public-pages/PublicHome";
import ApiDocs from "./components/public-pages/api-docs/ApiDocs";
import EmbeddedReport from "./components/public-pages/EmbeddedReport";
import Mapping from "./components/pages/mapping/Mapping";
import MappingTableView from "./components/pages/mapping/MappingTableView";
import {TableType} from "./gql/graphql";
import DataExplorer from "./components/pages/data-explorer/DataExplorer";
import IDMappingView from "./components/pages/mapping/IDMappingView";
import UnitView from "./components/pages/data-explorer/UnitView";
import MappingIntegrationsView from "./components/pages/mapping/MappingIntegrationsView";
import BrowseUnit from "./components/pages/data-explorer/BrowseUnits";
import CreateUnit from "./components/pages/data-explorer/CreateUnit";
import Settings from "./components/pages/settings/Settings";
import Occupancy from "./components/pages/portfolio/Occupancy";

function App() {
    const mailChimpUrl = process.env.REACT_APP_MAILCHIMP_URL
    if (!mailChimpUrl) {
        throw new Error("REACT_APP_MAILCHIMP_URL not set")
    }
    let supersetDomain = process.env.REACT_APP_SUPERSET_DOMAIN
        if (!supersetDomain) {
            if (process.env.NODE_ENV !== 'production') {
                throw new Error("REACT_APP_SUPERSET_DOMAIN not set")
            } else {
                supersetDomain = ""
            }
    }
    return (
        <Routes>
            <Route path='/' element={<PublicHomeShell/>}>
                <Route index element={<PublicHome mailchimpUrl={mailChimpUrl}/>} />
                <Route path='api-docs' element={<ApiDocs />} />
            </Route>
            <Route path='app' element={<AuthenticationGuard element={<AppShell/>}/>}>
                <Route index element={<Home />} />
                <Route path='dev' >
                <Route path='dev' index element={<Home />} />
                <Route path='tables' element={<Tables />} />
                <Route path='charts' element={<Charts />} />
                <Route path='form' element={<Form />} />
                <Route path='file-upload' element={<FileUploadPage />} />
                {process.env.NODE_ENV !== 'production' ? <Route path='report' element={<EmbeddedReport supersetDomain={supersetDomain}/>} /> : <></>}
                    </Route>
                <Route path='mapping'>
                    <Route index element={<MappingIntegrationsView />} />
                    <Route path="id-map" element={<IDMappingView/>}/>
                    <Route path="mapping_id/:data_type/:source_id" element={<MappingTableView/>} />
                    <Route path=":data_type/:source_id" element={<MappingTableView/>} />
                </Route>
                <Route path='workflow'>
                    <Route index element={<CreateUnit />} />
                    <Route path="create-unit" element={<CreateUnit />} />
                </Route>

                <Route path='data-explorer'>
                    <Route index element={<BrowseUnit />} />
                    <Route path="unit/:unit_id" element={<UnitView />} />

                    {Object.values(TableType).map(v => <Route path={v.toLowerCase()} key={"data-explorer-" + v} element={<DataExplorer type={v} />} />)}
                </Route>
                <Route path='integrations' element={<MappingIntegrationsView />} />
                <Route path='portfolio' element={<Occupancy />} />
                <Route path='settings' element={<Settings />} />
                <Route path='test3' element={<TestPage />} />
                <Route path='test4' element={<TestPage />} />
                <Route path='test5' element={<TestPage />} />
                <Route path='test6' element={<TestPage />} />

                <Route element={<NotFound/>}/>
            </Route>
        </Routes>
    );
}

export default App;
