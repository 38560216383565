import * as React from 'react'

import {
    ColumnDef,
    ExpandedState,
    FilterFn,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    useReactTable
} from '@tanstack/react-table'
import {Divider, FormLabel, Heading, Input, Stack, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {useEffect} from "react";

export type GenericTableProps<T> = {
    data: T[]
    columns: ColumnDef<T, any>[]
    expandedState?: ExpandedState
    subrows?: (originalRow: T, index: number) => undefined | T[]
    title?: string
    asOf?: string
    size?: string
    filter?: boolean
}
export default function GenericTable<T>({data, columns, subrows, title, asOf, expandedState = {}, size = "sm", filter = false}: GenericTableProps<T>) {
    const [expanded, setExpanded] = React.useState<ExpandedState>(expandedState)
    const [globalFilter, setGlobalFilter] = React.useState('')
    const table = useReactTable({
        data: data,
        columns: columns,
        state: {
            expanded,
            globalFilter,
        },
        onExpandedChange: setExpanded,
        getSubRows: subrows,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
        globalFilterFn: `includesString`,
        onGlobalFilterChange: setGlobalFilter,
    })

    return (
        <Stack borderWidth='1px' borderRadius='lg' p="5" overflowY={"auto"}>
            {title ? <Heading size={"sm"}>{title}</Heading> : ''}
            {asOf ? <Text size={"sm"}>as of {asOf}</Text> : ''}
            {filter ? <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
                placeholder="Search all columns..."
            /> : <></>}
            <Table size={size}>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr  key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th
                                    key={header.id}
                                    isNumeric={header.column.columnDef.meta?.isNumeric ?? false}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <Td key={cell.id}
                                    isNumeric={cell.column.columnDef.meta?.isNumeric ?? false}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Stack>
    )
}

// const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
//     // Rank the item
//     const itemRank = rankItem(row.getValue(columnId), value)
//
//     // Store the itemRank info
//     addMeta({
//         itemRank,
//     })
//
//     // Return if the item should be filtered in/out
//     return itemRank.passed
// }

function DebouncedInput({
                            value: initialValue,
                            onChange,
                            placeholder = "",
                            debounce = 500,
                            ...props
                        }: {
    value: string | number
    onChange: (value: string | number) => void
    placeholder?: string
    debounce?: number
}) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <Stack spacing={"1"} pb={"1em"}>
            <FormLabel>Search</FormLabel>
            <Input size={"sm"} placeholder={placeholder} value={value} onChange={e => setValue(e.target.value)} />
        </Stack>

    )
}
