import {useState} from "react";
import {Button, Icon, Input, HStack} from "@chakra-ui/react";
import {FcClock, FcCheckmark, FcHighPriority} from 'react-icons/fc';

export default function CustomForm({ status, message, onValidated } : {status:string|null, message:string|Error|null, onValidated:any}) {
    let [email, setEmail] = useState("")
    const submit = () =>
        email && email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });

    return (
        <HStack spacing="4" direction={{base: 'column', sm: 'row'}} maxW={{lg: '440px'}}>
            <Input placeholder="Enter your email here" type="email"
                   onChange={(e) => setEmail(e.target.value)}
                   required
            />
            <Button variant="primary" type="submit" flexShrink={0} onClick={submit}>
                Learn More
            </Button>
            {status === "sending" && <div style={{ color: "blue" }}><Icon as={FcClock} w={5} h={5}/></div>}
            {status === "error" && (
                <Icon as={FcHighPriority} w={5} h={5}/>
            )}
            {status === "success" && (
                <Icon as={FcCheckmark} w={5} h={5}/>
            )}
        </HStack>
    );
};
