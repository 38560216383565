import {Box, Flex, FormControl, FormLabel, Stack} from "@chakra-ui/react";
import React from "react";
import { FieldMappingViewProps } from "../FieldMappingView";
import ColumnSelect from "./ColumnSelect";

export default function AsIsMappingView(props: FieldMappingViewProps) {
    return <FormControl>
        <Stack spacing={"1em"}>
            <Flex>
                <Box w={"50%"}>
                    <FormLabel>Column</FormLabel>
                    <ColumnSelect {...props}/>
                </Box>
            </Flex>
        </Stack>
    </FormControl>
}
