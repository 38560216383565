import lau from'./team/lau.jpg';
import zach from'./team/zach.jpg';

export const EmailDomain = "sfrtech.co"
export const InfoEmailAddress = "info@" + EmailDomain

export const profileData = [
    {
        "src": zach,
        "name": "Zachary Ali",
        "title": "Co-founder, CEO",
        "bio": "Zach spent 4 years at Roofstock where he was Head of Business Development and " +
            "Director of Asset Management. As an early employee that saw the company grow from ~30 to ~700 people and " +
            "through Series E financing. Launched Asset Management and Owner Services divisions that grew " +
            "organically and through acquisitions. \n\n" +
            "Before that, he spent 4 Years at Pretium Partners as an Associate " +
            "in the Asset and Portfolio Management Team. Pretium is owner of Progress Residential, " +
            "largest private owner of Single-Family Rental Properties. \n\n" +
            "Zach started his career in investment banking covering US Banks, another massive industry that was in deep " +
            "need of digital solutions. BA from Columbia University ",
        "linkedIn": "https://www.linkedin.com/in/zacharyali/"
    },
    {
        "src": lau,
        "name": "Junyuan Lau",
        "title": "Co-founder, Head of Engineering",
        "bio": "Lau spent 4 Years as Staff Software Engineer leading a team at Thumbtack focused on " +
            "optimizing pricing, maintaining spend limits and payments systems for more than 100k pros a " +
            "year with more than 10mm transactions a year.\n\n" +
            "Prior to that, he spent 2 Years at Addepar integrating and normalizing $1T worth of financial assets to be " +
            "used by wealth managers and financial planners as reports and dashboards.\n\n" +
            "He took a non-traditional route to engineering. Began career in Finance spending 4 years in Financial Markets " +
            "covering Prime Services, Risk and Trading. Graduated NYU Stern. Completed MS in Comp Sci at UChicago",
        "linkedIn": "https://www.linkedin.com/in/junyuanlau/"
    }
]
