import React from "react";
import {Container, Heading, Stack, Text} from "@chakra-ui/react";
import SubRowTable from "../views/SubRowTable";

export default function Tables() {
    return (
        <Container>
            <Stack spacing="1">
                <Heading size={{base: 'xs', md: 'sm'}} fontWeight="medium">
                    Example tables
                </Heading>
                <Text color="muted">These are some example tables</Text>
            </Stack>
            <Stack>
                <SubRowTable/>
            </Stack>
        </Container>
    )
}
