interface DataProps {
    color: ChartColor,
    dataKey: string
}
export interface GenericChartProps {
    data: any[]
    dataProps: DataProps[]
}
export enum ChartColor {
    BLUE,
    RED,
    GREEN,
    YELLOW,
    CYAN,
    PINK
}

export function enumToThemeColor(color: ChartColor, theme:any) {
    switch (color) {
        case ChartColor.BLUE:
            return theme.colors.blue[400]
        case ChartColor.RED:
            return theme.colors.red[400]
        case ChartColor.GREEN:
            return theme.colors.green[400]
        case ChartColor.YELLOW:
            return theme.colors.yellow[400]
        case ChartColor.CYAN:
            return theme.colors.cyan[400]
        case ChartColor.PINK:
            return theme.colors.pink[400]
    }
    console.error("enum color not defined", color)
    return theme.colors.gray[400]
}
