import {Container, Heading, Text} from '@chakra-ui/react'
import SwaggerUI from 'swagger-ui-react'
import api from '../../../api.json'
import "swagger-ui-react/swagger-ui.css"

export default function ApiDocs() {
    return (
        <Container py={{base: '16', md: '24'}}>
            <Heading size={"md"}>API Docs</Heading>
            <Text color="muted">Coming Soon!</Text>
            {/*<SwaggerUI spec={api} />*/}
        </Container>
    )
}
