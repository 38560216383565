import {Box, Code, Flex, FormControl, FormLabel, Input, Stack, Text} from "@chakra-ui/react";
import React from "react";
import { FieldMappingViewProps } from "../FieldMappingView";
import ColumnSelect from "./ColumnSelect";
import {FastField} from "formik";

export default function ParseStringAsTimestampMappingView(props: FieldMappingViewProps) {
    return <FormControl>
        <Stack spacing={"1em"}>
            <Flex>
                <Box w={"50%"}>
                    <FormLabel>Column</FormLabel>
                    <ColumnSelect {...props}/>
                    <FormLabel>Timestamp Format</FormLabel>
                    <Input as={FastField}
                           placeholder={"YYYY-MM-DD HH:MI:SS"}
                           size={"sm"}
                           id={`input.fieldMappings[${props.idx}].parseTimestampFormat`}
                           name={`input.fieldMappings[${props.idx}].parseTimestampFormat`}
                           value={props.formik?.parseTimestampFormat ?? ""}
                           onChange={props.formikOnChange}/>
                    <Text>Examples:</Text>
                    <Stack>
                    <Code>
                        YYYY-MM-DD HH:MI:SS
                        </Code><Code>
                        YYYY-MM-DD HH:MI:SS.MS
                    </Code>
                    </Stack>
                </Box>
            </Flex>
        </Stack>
    </FormControl>
}
