import {ApolloClient, ApolloProvider, InMemoryCache,} from "@apollo/client";
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {setContext} from "@apollo/client/link/context";
import {createUploadLink} from "apollo-upload-client";

interface ApolloWrapperProps {
    children?: React.ReactNode;
}

export default function ApolloProviderWrapper({children}: ApolloWrapperProps){
    const graphQLUrl = process.env.REACT_APP_BACKEND_GRAPHQL_URL
    if (!graphQLUrl) {
        throw new Error("process.env.REACT_APP_BACKEND_GRAPHQL_URL not defined")
    }
    const domain = process.env.REACT_APP_AUTH0_DOMAIN
    if (!domain) {
        throw new Error("process.env.REACT_APP_AUTH0_DOMAIN not defined")
    }
    const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE
    if (!auth0Scope) {
        throw new Error("process.env.REACT_APP_AUTH0_SCOPE not defined")
    }
    const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE
    if (!auth0Audience) {
        throw new Error("process.env.REACT_APP_AUTH0_AUDIENCE not defined")
    }
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [bearerToken, setBearerToken] = useState("");

    useEffect(() => {
        const getToken = async () => {
            const token = isAuthenticated ? await getAccessTokenSilently(
                {
                    authorizationParams: {
                        audience: auth0Audience,
                        scope: auth0Scope,
                    },
                }
            ) : "";
            setBearerToken(token);
        };
        getToken();
    }, [getAccessTokenSilently, isAuthenticated]);

    const authLink = setContext((request, { headers, ...rest }) => {
        if (!bearerToken) return { headers, ...rest };
        return {
            ...rest,
            headers: {
                ...headers,
                authorization: `Bearer ${bearerToken}`,
            },
        };
    });

    const uploadLink = createUploadLink({
        uri: graphQLUrl
    });

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(uploadLink),
    });
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
