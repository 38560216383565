import {Select} from "@chakra-ui/react";
import React from "react";
import { FieldMappingViewProps } from "../FieldMappingView";

export default function ColumnSelect(props: FieldMappingViewProps) {
    return <Select
        onChange={props.formikOnChange}
        value={props.formik.sourceColumn}
        placeholder='Select column'
        size={"sm"}
        name={`input.fieldMappings[${props.idx}].sourceColumn`}>
        {props.availableColumns.map(c => <option key={`${props.formik.targetColumn}-${c}`}>{c}</option>)}
    </Select>
}
