import {Alert, AlertIcon, useDisclosure} from '@chakra-ui/react'
import { AlertDescription } from '@chakra-ui/react'
import {AlertTitle, Box, Button, ButtonGroup, CloseButton, Flex, Stack, Text } from '@chakra-ui/react'

interface NotificationProps {
    text: string
    onClose: ()=> void
}
export default function AlertTop(props: NotificationProps) {
    return <Alert status='success'>
            <AlertIcon />
            <Box>
                <AlertTitle>Success!</AlertTitle>
                <AlertDescription>
                    {props.text}
                </AlertDescription>
            </Box>
            <CloseButton
                alignSelf='flex-start'
                position='relative'
                right={-1}
                top={-1}
                onClick={props.onClose}
            />
        </Alert>
}
